import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/talAirplane1.jpg";
import imageFlotteTal from "images/flotte-tal1.jpg";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="space-y-5 text-center">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xlcontent-center relative left-1/2 transform -translate-x-1/2"
            title="Quiet ingenuity: 120,000 lunches and counting" 
          >
            CONDITIONS DE TRANSPORT
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
      id="single-entry-content"
      className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
    >


<h3><strong><span>ARTICLE 01- DEFINITIONS</span></strong></h3>
<p><span>Au sens des présentes conditions et sauf exception dans le texte, les termes suivants sont employés avec le sens indiqué ci-après : </span></p>
<p><strong><span>Affrètement</span></strong><span> désigne l'opération par laquelle le transporteur ayant conclu un contrat de transport avec le passager « transporteur contractuel » délégué a un autre transporteur « transporteur de fait » la charge d'effectuer la totalité ou une partie du transport aérien.  </span></p>
<p><strong><span>Agent Accrédité</span></strong><span> désigne une personne physique ou morale que le transporteur a agréée pour le représenter dans la vente de titres de transport aérien sur ses services ou ceux d'un autre transporteur si cet agent y est autorisé.  </span></p>
<p><span><strong><span>Tassili Airlines</span></strong></span><span><span> </span></span><span>désigne la « Société Tassili <span>Airlines », Société par action, dont le siège social est situé 168, Avenue Hassiba Ben Bouali, Tour B, El Hamma Alger immatriculée au registre du <span>commerce sous le numéro 16/00 - 0122375 B98</span> </span></span></p>
<p><strong><span>Animaux de Compagnie</span></strong><span> désigne l'animal de compagnie accompagnant en cabine ou en soute le passager qui en est soit le propriétaire, soit une personne physique qui en assume la responsabilité pour le compte du propriétaire au cours du voyage.  </span></p>
<p><strong><span>Arrêt Volontaire</span></strong><span> ou « Stop-over » désigne un arrêt programmé par le passager au cours de son voyage, à une escale située entre le point de départ et le point de destination et figurant sur le billet ou dans les horaires.  </span></p>
<p><strong><span>Bagages</span></strong><span> désigne les effets et autres objets personnels accompagnant le passager au cours de son voyage. Sauf disposition contraire, ce terme désigne à la fois les bagages enregistrés et les bagages non enregistrés.  </span></p>
<p><strong><span>Bagages Cabine</span></strong><span> désigne le bagage qui est transporté et rangé dans la cabine sous le contrôle et la garde du passager, il est communément appelé <strong>bagage à main</strong> ou <strong>bagage non enregistré</strong>.</span></p>
<p><strong><span>Bagages Enregistrés</span></strong><span> désigne les bagages dont le transporteur a accepté la garde et pour lesquels a été délivrée une </span><span>étiquette de bagage<span>.</span></span></p>
<p><span><strong><span>Billet </span></strong></span><span>désigne un document en cours de validité établissant le droit au transport, délivré ou autorisé par Tassili Airlines ou son agent accrédité et complété éventuellement d'une fiche d'identification pour les bagages enregistrés. Il matérialise le contrat de transport. Il comprend les avis aux passagers, tels que prévus dans la convention.  </span></p>
<p><strong><span>Billet Complémentaire</span></strong><span> désigne un billet dont l'émission est rendue nécessaire du fait du nombre important de coupons d'un billet principal dont l'ensemble constitue un seul et même contrat de transport.  </span></p>
<p><strong><span>Billet électronique</span></strong><span> désigne le billet sauvegardé par le transporteur ou à sa demande par un système informatique de réservation et dont attestent le memo-voyage appelé aussi « itinéraire/reçu », le coupon de vol électronique, émis par le transporteur ou par un agent accrédité.  </span></p>
<p><strong><span>Cause Endogène</span></strong><span> désigne toutes causes relevant de la responsabilité de la compagnie qui découlent généralement des dysfonctionnements ponctuels des différentes interventions lors du traitement d’un vol, telles qu’attente passager, acceptation de passagers en dernière minute, panne avion, panne des équipements, de traitement avion, difficultés de chargement, rotation (retour tardif A/C), retard de l’équipage</span></p>
<p><strong><span>Cause Exogène</span></strong><span> désigne toutes causes externes ne relevant pas de la responsabilité des compagnies telles que gestionnaire d’aéroport, conditions météo, circulation aérienne, services de sureté, mouvements sociaux.</span></p>
<p><strong><span>Code de Désignation du Transporteur</span></strong><span> désigne le code attribué par IATA, identifiant chaque transporteur membre de cet organisme en deux ou plusieurs caractères alphabétiques, numériques ou alphanumériques et figurant sur le billet accolé au numéro de vol.  </span></p>
<p><strong><span>Circonstances extraordinaires</span></strong><span> Il devrait être considéré qu'il y a circonstance extraordinaire, lorsqu'une décision relative à la gestion du trafic aérien concernant un avion précis pour une journée précise génère un retard important, un retard jusqu'au lendemain ou l'annulation d'un ou de plusieurs vols de cet avion, bien que toutes les mesures raisonnables aient été prises par le transporteur aérien afin d'éviter ces retards ou annulations. De telles circonstances peuvent se produire, en particulier, en cas d'instabilité politique, de conditions météorologiques incompatibles avec la réalisation du vol concerné, de risques liés à la sécurité, de défaillances imprévues pouvant affecter la sécurité du vol, ainsi que de grèves ayant une incidence sur les opérations du transporteur.</span></p>
<p><span><strong><span>Contrat de Transport</span></strong></span><span><span> </span></span><span>désigne les déclarations et stipulations figurant sur le billet, identifiées comme telles et incluant.</span></p>
<p><span><strong><span>Convention</span></strong></span> <span>désigne, selon les cas :  </span></p>
<p><span>(a)La Convention pour l'unification de certaines règles relatives au transport aérien international, signée à Varsovie, le 12 octobre 1929. </span></p>
<p><span>(b)Le Protocole de La Haye du 28 septembre 1955, modifiant la Convention de Varsovie.  </span></p>
<p><span>(c) La Convention complémentaire de Guadalajara, du 18 septembre 1961.  </span></p>
<p><span>(d)Les Protocoles de Montréal n° 1, 2 et 4 (1975), modifiant la Convention de Varsovie.  </span></p>
<p><span>(e) La Convention pour l'unification de certaines règles relatives au transport aérien international, signée à Montréal le 28 mai 1999.<strong>  </strong></span></p>
<p><strong><span>Coupon </span></strong><span>désigne soit un coupon de vol papier, soit un coupon électronique, chacun d'entre eux comportant le nom du passager devant effectuer le vol identifié sur ce coupon<strong>.  </strong></span></p>
<p><span><strong><span>Coupon de vol</span></strong></span><span><span> </span></span><span>désigne la partie du billet identifiée comme « valable pour le transport » ou, dans le cas d'un billet électronique, le coupon électronique indiquant les points précis entre lesquels le passager doit être transporté<strong>. </strong></span></p>
<p><span><strong><span>Coupon électronique</span></strong></span><span><span> </span></span><span>désigne soit un coupon de vol papier, soit un coupon électronique, sauvegardé sur support numérique dans le système de réservation du transporteur et comportant le nom du passager devant effectuer le vol identifié<strong>.  </strong></span></p>
<p><span><strong><span>Coupon passager ou « reçu-passager »</span></strong></span><span><span> </span></span><span>désigne la partie du billet, émis par le transporteur ou en son nom et doit être conservé par le passager.  </span></p>
<p><strong><span>Décret Exécutif n°16-175</span></strong><span> Règlement national fixant les conditions et modalités d’application des droits des passagers de transport aérien public.</span></p>
<p><span><strong><span>Dommage</span></strong></span><span><span> r</span></span><span>ecouvre le préjudice survenu en cas de mort ou de lésion corporelle qu'un passager pourrait subir ou résultant d'un retard, d'une perte totale ou partielle ou de tout autre préjudice survenant du fait du transport aérien.</span></p>
<p><span><strong><span>Droit de Tirage Spécial (DTS)</span></strong></span><span><span> </span></span><span>désigne une unité de compte du Fonds Monétaire International (FMI) dont la valeur est déterminée périodiquement par ce dernier, à partir du cours de plusieurs monnaies de référence.  </span></p>
<p><strong><span>Escales intermédiaires</span></strong><span> désigne les points, à l'exception des points d’origine et de destination, indiqués sur le billet en tant qu'escales prévues sur l'itinéraire du passager.  </span></p>
<p><span><strong><span>Étiquette de Bagages ou « Tag »</span></strong></span><span><span> </span></span><span>désigne la partie de la fiche d'identification apposée sur le bagage enregistré.  </span></p>
<p><strong><span>Fiche d'Identification</span></strong><span> <span>désigne un bulletin émis par le transporteur aux seules fins de l'identification des bagages enregistrés et comprenant une partie apposée sur le bagage « Étiquette de Bagages » ou « Tag » et une autre remise au passager pour l'identification dudit Bagage « Reçu de Bagages ».</span></span></p>
<p><span><strong><span>Force Majeure</span></strong></span><span><span> </span></span><span>désigne les circonstances extraordinaires et imprévisibles en dehors du contrôle de celui qui l’invoque et qui n’auraient pu être évitées malgré toutes les diligences déployées<strong>.</strong></span></p>
<p><strong><span>Frais d'Émission ou « <span>Ticketing</span> <span>Fees</span> »</span></strong><span> désigne des frais facturés, le cas échéant, au passager, par le transporteur ou son agent accrédité en contrepartie de la prestation d’émission du billet. Leur montant est fixé par l'émetteur du billet (le transporteur ou l'agent accrédité, selon le cas). </span></p>
<p><strong><span>Frais de Services</span></strong><span> désigne des frais facturés, le cas échéant, au passager par le transporteur et/ou par son agent accrédité, notamment en contrepartie de la modification « frais de modification », de la réémission « frais de réémission » ou de remboursement « frais de remboursement » d’un billet.  Le passager est informé par le transporteur du montant des frais de services applicables avant la finalisation de sa réservation.  Le montant de ces frais est disponible auprès du transporteur ou de son agent accrédité.  </span></p>
<p><span><strong><span>Franchise de Bagages</span></strong></span><span> désigne la quantité maximale de bagages (en nombre et/ou en poids et/ou en dimension) fixée par le transporteur avec laquelle chaque passager peut voyager.  </span></p>
<p><span><strong><span>Heure Limite d’Enregistrement ou « HLE » ou « Fin d'Enregistrement »</span></strong></span><span><span> </span></span><span>désigne la limite horaire avant laquelle le passager doit avoir effectué ses formalités, notamment d'enregistrement, y compris le cas échéant, de ses bagages et être en possession de sa carte d'embarquement ou d'accès à bord.  </span></p>
<p><strong><span>Horaires ou « Indicateurs Horaires »</span></strong><span> <span>désigne le relevé des heures de départ et d'arrivée des avions, tel que mentionné dans les guides horaires publiés par le Transporteur, ou sous son autorité, ou tel que porté à la connaissance du public par voie électronique. </span></span></p>
<p><span><strong><span>IATA ou « International Air Transport Association »</span></strong></span><span><span> désigne</span></span><span> l'Association du Transport Aérien International, créée en avril 1945 à Montréal, dont la mission consiste à encourager le développement de transports aériens sûrs, réguliers et économiques, favoriser le commerce aérien et étudier les problèmes qui s'y rapportent. </span></p>
<p><span><strong><span>Instruction 08-2001 de la Banque d’Algérie </span></strong></span><span>règlement fixant les conditions et les modalités d’émission de titres de transport aérien et transfert des excédents de recettes.</span></p>
<p><strong><span>Jours</span></strong><span> désigne les jours du calendrier comprenant les sept jours de la semaine, étant entendu que dans le cas d'une notification, le jour d'envoi n'est pas compté et que, pour déterminer la durée de validité d'un billet, le jour d'émission du billet ou le jour du départ du vol n'est pas compté<strong>.  </strong></span></p>
<p><strong><span>Loi N°98-06 du 27 Juin 1998 modifiée et complétée</span></strong><span> désigne les règles générales relatives à l’aviation civile algérienne.</span></p>
<p><span><strong><span>Mémo-Voyage ou « Itinéraire/reçu »</span></strong></span><span><span> </span></span><span>désigne un ou plusieurs documents que le transporteur émet à l'attention du passager qui attestent de l'émission d'un billet électronique et qui comportent son nom, des informations sur le vol et les avis aux passagers.  </span></p>
<p><strong><span>Passager</span></strong><span> désigne toute personne en possession d'un billet, en dehors des membres de l'équipage, transportée ou devant être transportée par avion.  </span></p>
<p><strong><span>Passager à Mobilité Réduite</span></strong><span> <strong>ou « PMR »</strong> désigne toute personne dont la mobilité est réduite lorsqu'elle utilise un moyen de transport, en raison d'un handicap physique (sensoriel ou locomoteur, permanent ou temporaire), d'une déficience intellectuelle, de son âge ou de toute autre cause de handicap et dont la situation exige une attention spéciale et l'adaptation à ses besoins des services mis à la disposition de tous les passagers.  </span></p>
<p><strong><span>Personne ayant droit à indemnisation</span></strong><span> désigne le passager ou toute personne pouvant prétendre à réparation au titre dudit passager conformément au droit applicable et aux présentes conditions générales de transport. </span></p>
<p><strong><span>Reçu de Bagages</span></strong><span> désigne la partie de la fiche d'identification délivrée au passager par le transporteur, afférent au transport des bagages enregistrés. </span></p>
<p><strong><span>Réservation</span></strong><span> désigne toute demande de transport effectuée par un passager enregistrée sur le par le transporteur aérien ou son agent accrédité.  </span></p>
<p><span><strong><span>Site Internet de Tassili Airlines </span></strong></span><span>désigne le site Internet </span><span><a href="https://www.tassiliairlines.dz"><span>www.tassiliairlines.dz</span></a></span><span><span>  </span> </span><span></span></p>
<p><strong><span>Tarifs</span></strong><span> désigne le tarif d'un transport réservé par le passager, dans une classe de réservation, pour des parcours, des vols et, le cas échéant, des dates données. </span></p>
<p><span><strong><span>Tarif HT ou « Tarif Hors Taxe »</span></strong></span> <span>désigne le tarif facturé au passager, hors taxes et hors frais d'émission.  </span></p>
<p><strong><span>Tarif Typologique</span></strong><span> Tarif dédié à une catégorie de clientèle spécifique (Jeune, couple, famille, sénior)</span></p>
<p><strong><span>Tarif TTC ou « Tarif Toutes Taxes Comprises »</span></strong><span> désigne le tarif HT majoré des taxes et des surcharges. </span></p>
<p><strong><span>Taxes</span></strong><span> désigne les frais, taxes et redevances imposés par un gouvernement, par le gestionnaire d'un aéroport, ou par toute autre autorité et tels que définis à l'article 4 ci-dessous.</span></p>
<p><strong><span>Transport Aérien ou « Voyage Aérien »</span></strong><span> désigne le transport par aéronef du passager et de ses bagages au sens de la convention applicable. </span></p>
<p><strong><span>Transporteur</span></strong><span> désigne Tassili Airlines ou tout autre transporteur dont le code de désignation apparaît sur le billet ou sur un billet complémentaire. </span></p>
<p><strong><span>Transporteur de Fait ou « Transporteur Effectif »</span></strong><span> désigne le transporteur réalisant effectivement le vol.  </span></p>
<p><strong><span>Transporteur Contractuel ou « Transporteur Contractant »</span></strong><span> désigne le transporteur avec lequel le passager a conclu un contrat de transport et dont le code de désignation apparaît sur le billet. </span></p>
<p><strong><span>Vol en partage de codes ou « Code Share »</span></strong><span> désigne un vol opéré par un transporteur aérien pouvant être soit le transporteur auprès duquel le passager a conclu un contrat de transport « Transporteur Contractant » ou « Transporteur Contractuel », soit un autre transporteur (Transporteur assurant le vol ou « Transporteur de Fait ») auquel le transporteur contractant a associé son code de désignation. </span></p>
<p><strong><span>Vol par voie ferrée/maritime/routière</span></strong><span> désigne un « transport combiné » aux termes duquel le transport aérien et les autres modes de transport sont vendus conjointement et peuvent être réalisés sous différents régimes de responsabilité. </span></p>
<p><strong><span>Vol intérieur ou « vol national »</span></strong><span> désigne tout vol dont la ville de départ et la ville de destination sont situées à l'intérieur d'un même état, en continuité territoriale. </span></p>
<p><strong><span>Vol international</span></strong><span> désigne, au sens de la convention, tout vol pour lequel le point de départ et le point de destination et, éventuellement, le point d'escale sont situés sur le territoire d'au moins deux états parties à la convention nonobstant les escales intermédiaires ou changements d'appareils, ou dans un seul État si une escale intermédiaire est prévue dans un autre état qu'il soit ou non partie à la convention. </span></p>









<h3><strong>ARTICLE 02-DOMAINE D'APPLICATION </strong></h3>
<h2><strong>2.1 </strong><strong>Généralités </strong></h2>
<p className="text-justify">Sous réserve des dispositions prévues dans les articles 2.2, 2.4, les présentes Conditions Générales de Transport font partie intégrante du contrat de transport conclu entre le passager et la compagnie. Elles s'appliquent aux vols ou aux segments de vol pour lesquels le nom et le code de la désignation de Tassili Airlines &laquo;&nbsp;SF&nbsp;&raquo; apparaissent sur le billet ou le coupon correspondant.</p>
<p className="text-justify">Tassili Airlines se réserve le droit de réviser les présentes Conditions Générales de Transport. Les modifications apportées</p>
<h2><strong>2.2 </strong><strong>&nbsp;Affrètement </strong></h2>
<p>Si le transport est effectué dans le cadre d'un contrat d'affrètement, les présentes Conditions Générales de Transport ne s'appliquent en particulier que lorsque celles-ci s'avèrent plus favorables que celles du transporteur de fait.</p>
<p className="text-justify">Si un autre transporteur, que celui désigné sur le billet opère le transport aérien concerné. Tassili Airlines informe le passager de l'identité du transporteur fait, dès qu'elle est connue. En tout état de cause, le passager sera informé au plus tard lors de l'enregistrement.</p>
<h2><strong>2.3 </strong><strong>Partage de codes </strong><strong>/Code Shares</strong></h2>
<p className="text-justify">Tassili Airlines peut conclure des accords de transport avec d'autres transporteurs nommés &laquo;&nbsp;Partages de codes&nbsp;&raquo;.</p>
<p className="text-justify">Cela signifie que même si le passager est titulaire d'une réservation faite auprès de Tassili Airlines, ou même si le passager est en possession d'un billet portant le nom ou le code de désignation &laquo;&nbsp;SF&nbsp;&raquo;, le transporteur exploitant peut ne pas être le même transporteur que celui indiqué sur le billet.</p>
<h2><strong>2.4 </strong><strong>Prédominance de la loi</strong></h2>
<p className="text-justify">Ces Conditions Générales de Transport sont applicables sauf si elles s'avèrent non conformes aux dispositions réglementaires et/ou à la législation applicable, auquel cas ces règles et lois prévaudront.</p>
<p className="text-justify">Si l'une des dispositions des présentes Conditions Générales de Transport est invalide en vertu des lois applicables, les autres dispositions restent néanmoins valables</p>
<h3><strong>ARTICLE 03-BILLETS&nbsp;</strong></h3>
<h2>2.5 <strong>Dispositions générales</strong>&nbsp;:</h2>
<h4>2.5.1 Le billet atteste, jusqu'à preuve du contraire, de l'existence d'un contrat de transport, tant dans sa conclusion que dans son contenu, entre le transporteur et le passager dont le nom figure sur le billet.&nbsp;</h4>
<h4>2.5.2 La prestation de transport n'est fournie qu'au(x) passager(s) désigné(s) sur le billet. Le transporteur se réserve le droit de procéder à la vérification documentaire de l'identité de ces passagers. Le passager doit ainsi pouvoir justifier auprès du transporteur, à tout moment de son voyage, de son identité ainsi que de ceux dont il a la responsabilité.&nbsp;</h4>
<h4>2.5.3 Un billet n'est pas cessible, sous réserve de la réglementation applicable en vigueur. Si une autre personne que celle qui doit voyager se présente avec un billet à des fins de transport ou de remboursement, le transporteur n'assumera aucune responsabilité si, en toute bonne foi, la personne qui présente le billet est transportée ou remboursée.</h4>
<h4>2.5.4 Certains billets, vendus à des tarifs spécifiques, sont partiellement ou totalement non modifiables et/ou non remboursables. Il appartient au passager, lors de la réservation, de veiller aux conditions applicables à l'utilisation de son billet.</h4>
<h4>2.5.5 Certains billets, vendus à des tarifs combinés, les conditions du tarif le plus restrictif s'appliquent sur l'ensemble des coupons du billet.</h4>
<h4>2.5.6 Le billet étant soumis à des conditions formelles obligatoires, celui-ci demeure en permanence la propriété du transporteur émetteur.&nbsp;</h4>
<h4>2.5.7 Le passager ne peut être transporté sur un vol que s'il présente un billet en cours de validité, émis à son nom et confirmé sur le vol prévu. Le passager doit fournir un document de voyage à l'enregistrement.</h4>
<h3><strong>2.6 </strong><strong>Durée de validité&nbsp;de billet </strong></h3>
<h4>2.6.1 Sauf dispositions contraires contenues soit dans le billet, soit dans les présentes Conditions Générales de Transport, ou sauf le cas de tarifs affectant la durée de validité d'un billet, tel qu'indiqué au passager au moment de l'achat du billet ou sur le billet lui-même.</h4>
<p className="text-justify">Un billet est valable au transport :&nbsp;</p>
<p className="text-justify">Un an, à compter de la date d'émission, ou</p>
<p className="text-justify">Un an, à compter de la date d'utilisation du premier segment (coupon), si celle-ci intervient dans l'année de la date d'émission du billet.</p>
<p className="text-justify">Un billet est valable au remboursement&nbsp;</p>
<p className="text-justify">Un an, à compter de la date d'émission ou,</p>
<p className="text-justify">Un an, à compter de la date d'utilisation du premier segment (coupon).</p>
<h4>2.6.2 Lorsque le passager en possession d'un billet en cours de validité se trouve empêché de voyager pendant la durée de validité de son billet, car au moment o&ugrave; il demande une réservation sur un vol, Tassili Airlines n'est pas en mesure de confirmer la réservation souhaitée par le passager, soit :&nbsp;</h4>
<p className="text-justify">La validité de ce billet peut être prorogée, et ce, a l'appréciation de Tassili Airlines.</p>
<p className="text-justify">Le remboursement de la partie non utilisée du billet, dans les conditions prévues à l'article 11 ci-après, même dans le cas o&ugrave; les conditions tarifaires appliquées sur le billet ne permettent le remboursement. </p>
<p className="text-justify">Le rerouting volontaire peut être accepté en application d'un réajustement tarifaire s'il y a lieu.</p>
<h4>2.6.3 Lorsque, après avoir commencé son voyage, le passager se trouve empêché, pour des raisons de santé, de le poursuivre durant la période de validité du billet. Le transporteur pourra proroger la validité du billet jusqu'à la date à laquelle le passager sera en mesure de voyager à nouveau ou jusqu'à la date du premier vol disponible, sous réserve que le passager remette un certificat médical prouvant les raisons de santé l'ayant empêché de continuer son voyage et que ces raisons de santé n'aient pas été connues lors de la réservation.</h4>
<h4>2.6.4 La prorogation visée à l'article 3.2.2 ne débutera qu'au point o&ugrave; le voyage a été interrompu et vaudra pour un transport dans la classe du tarif initialement payé. Lorsque les coupons de vol non encore utilisés comportent un ou plusieurs arrêts volontaires, la validité du billet pourra être prorogée pour une durée maximale de trois (03) mois, à compter de la date indiquée sur le certificat médical remis. De même, le transporteur pourra proroger, sur demande, la validité des billets des membres de la famille proche accompagnant le passager, sous réserve du respect des conditions de preuve définies ci-dessus.&nbsp;</h4>
<h4>2.6.5 En cas de décès d'un passager au cours de son voyage, les billets des personnes accompagnant le défunt pourront être modifiés, soit en écartant la notion de séjour minimum, soit en prorogeant la durée de validité de ces billets. En cas de décès survenu dans la famille proche d'un passager dont le voyage a commencé, la validité de ses billets et de ceux des membres de sa famille proche voyageant avec lui pourra être modifiée de la même fa&ccedil;on. Toute modification mentionnée ci-dessus ne pourra être effectuée qu'après réception d'un certificat de décès en bonne et due forme. La prorogation mentionnée ci-dessus ne débutera qu'au point o&ugrave; le voyage a été interrompu et vaudra pour un transport dans la classe du tarif TTC payé. Toute prorogation ne pourra excéder quarante-cinq (45) jours à compter de la date du décès.&nbsp;</h4>
<h3><strong>2.7 </strong><strong>Force&nbsp;Majeure invoquée par le Passager&nbsp;:</strong></h3>
<p className="text-justify">&lt;p '&gt;Si le Passager possède un billet qu'il n'a pas utilisé ou qu'il a utilisé partiellement, et qu'il est dans l'impossibilité de voyager pour une raison de force&nbsp;majeure, telle que définie à l'article 1, le transporteur accordera au passager, sous réserve des frais de services applicables&nbsp;:</p>
<p className="text-justify">Un remboursement correspondant au tarif toutes taxes comprises (TTC) des segments du billet non utilisé de son billet (non remboursable et/ou non modifiable).</p>
<p className="text-justify">Un changement de date pour un voyage ultérieur sur les vols de Tassili Airlines.</p>
<p className="text-justify">Un changement d'itinéraire (rerouting) pour un voyage ultérieur sur les vols de Tassili Airlines.</p>
<p className="text-justify">à condition que le passager prévienne Tassili Airlines avant la date du vol prévu et qu'il fournisse les preuves de ce cas de force&nbsp;majeure.</p>
<h3><strong>2.8 </strong><strong>Ordre d'utilisation des coupons de vols&nbsp;</strong></h3>
<h4>2.8.1 Le tarif TTC, fixé en fonction des données, des dates de vols et des parcours mentionnés sur le billet, correspond à un point de départ et à un point de destination, via toute escale intermédiaire prévue lors de l'achat du billet et fait partie intégrante du contrat de transport. Le tarif appliqué à la date d'émission du billet n'est valable que pour un billet utilisé intégralement en respectant l'ordre et les dates du parcours réservé (ordre séquentiel des coupons de vol).</h4>
<h4>2.8.2 Le billet ne sera pas honoré et perdra sa validité pour le transport et pour le remboursement, si tous les coupons ne sont pas utilisés dans l'ordre indiqué sur le billet.</h4>
<h4>2.8.3 Le respect de l'ordre séquentiel des coupons de vol n'est pas applicable sur les vols du réseau domestique, sauf si le tarif appliqué sur le billet est valable pour l'aller-retour ensemble.</h4>
<h3><strong>2.9 </strong><strong>Identification du transporteur</strong></h3>
<p className="text-justify">L'identification du transporteur peut figurer en abrégé, par le biais de son code de désignation de compagnie aérienne, ou autrement, sur le billet (tel que défini à l'article 1).</p>
<p className="text-justify">L'adresse du transporteur est considérée comme étant celle du siège social ou du lieu principal de son exploitation.</p>
<h3><strong>ARTICLE 04-TARIFS, TAXES, DROITS ET REDEVANCES </strong></h3>
<h3><strong>4.1 </strong><strong>: Tarifs Passagers&nbsp;:</strong></h3>
<h4> Les tarifs des billets s'appliquent exclusivement au transport de l'aéroport d'origine à l'aéroport de destination, aux vols indiqués sur le billet.</h4>
<h4> Les tarifs n'incluent pas le service de transport de surface entre les aéroports et entre les aéroports et les terminaux urbains.</h4>
<h4> Les tarifs applicables sont ceux qui sont publiés ou calculés par Tassili Airlines conformément aux règles tarifaires en vigueur à la date d'achat du billet, pour le ou les vols indiqués sur le billet du point de départ au point de destination, pour une classe de transport donnée.&nbsp;</h4>
<h4> Sous réserve de l'article 4.2, les tarifs applicables sont ceux qui sont publiés ou calculés par Tassili Airlines conformément aux règles tarifaires en vigueur à la date d'émission du billet. Si le passager modifie l'itinéraire, les dates de voyages ou la classe de réservation, cela peut avoir un impact sur le tarif appliqué et peut générer des frais supplémentaires qui seront à la charge du passager.</h4>
<h4> Lors de la réservation, le passager est informé du tarif TTC du billet et des frais d'émission si ceux-ci sont appliqués, ainsi que des conditions du tarif appliqué.</h4>
<h4> Les réductions commerciales allouées par Tassili Airlines pour une certaine typologie de passagers, telles que&nbsp;: bébé (INF), enfant (CHD), sénior (CD), jeune (ZZ), sont applicables, si les conditions le permettent, sur présentation d'un justificatif.</h4>
<h3> <strong>: Taxes, Redevances et Frais</strong>&nbsp;:</h3>
<h4> Les taxes ou frais applicables imposés par le gouvernement ou une autre autorité, ou par le gestionnaire d'un aéroport, et en vigueur à la date du voyage, sont à la charge du passager.</h4>
<h4> Le passager est avisé des taxes, redevances et frais appliqués ajoutés au tarif HT du billet et apparaissent séparément sur le billet.</h4>
<h4> Ces frais, taxes et redevances peuvent être créés ou augmentés par un gouvernement, par une autre autorité ou par le gestionnaire d'un aéroport après la date de réservation du billet. Dans le cas d'un changement de date, le passager devra en acquitter le montant correspondant.</h4>
<h4>4.2.4 Dans le cas o&ugrave; le passager annule son voyage sur un vol pour lequel il <a>dispose</a> d'une réservation confirmée, ce passager a le droit d'obtenir le remboursement de ces taxes, redevances aéroportuaires et autres frais suscités, et ce, dans la limite de la validité du billet.</h4>
<h3><strong>4.3 </strong><strong>&nbsp;Surcharge&nbsp;:</strong></h3>
<h4> Des frais supplémentaires peuvent être ajoutés au montant du tarif à payer (tels que la surcharge carburant).</h4>
<h4> Dans le cas o&ugrave; le passager annule son voyage sur un vol pour lequel il dispose d'une réservation confirmée, Tassili Airlines n'autorise pas le remboursement de la surcharge carburant.</h4>
<h3><strong>4.4 </strong><strong>&nbsp;Monnaie&nbsp;:</strong></h3>
<p className="text-justify">Tarifs, taxes, redevance, frais et surcharges sont payables dans la monnaie du pays o&ugrave; commence le transport. Lorsque le paiement est effectué dans une devise autre que celle dans laquelle le tarif est publié, ce paiement sera effectué au taux de change établi et conformément à la loi en vigueur &laquo;&nbsp;Instruction 08-2001 de la Banque d'Algérie fixant les conditions et les modalités d'émission des titres de transport aérien&nbsp;&raquo;.</p>
<h3><strong>ARTICLE 05-RESERVATION </strong></h3>
<h2><strong>5.1 </strong><strong>Exigences liées à la réservation&nbsp;:</strong></h2>
<h3> Les réservations ne sont confirmées qu'après avoir été enregistrées comme acceptées par le transporteur.</h3>
<h3> Certains tarifs comportent des conditions qui limitent ou excluent le droit de modification ou d'annulation de réservation.</h3>
<h2><strong>5.2 </strong><strong>&nbsp;Date limite de paiement du billet&nbsp;:</strong></h2>
<p className="text-justify">Si le passager ne paie pas le billet avant l'heure limite d'émission du billet spécifiée, comme indiqué par le transporteur ou son agent accrédité, la réservation peut être annulée.</p>
<h3><strong>5.3 </strong><strong>&nbsp;Données Personnelles</strong></h3>
<h4>5.3.1 Le passager reconnait que des données personnelles ont été fournies au transporteur aux fins de&nbsp;; faire une réservation, achat d'un billet, obtention de services annexes, élaboration et fourniture services, facilitant les procédures d'immigration et d'entrée et mettant ces données à la disposition des agences gouvernementales, dans le cadre de son voyage. A ces fins, le passager autorise le transporteur à conserver, à utiliser ces données et à les transmettre à ses propres agences, agent accrédité, agences de voyages agréés ayant émis le billet, agences gouvernementales, autres transporteurs ou fournisseurs de services et prestataires mentionnés ci-dessus.</h4>
<h4>5.3.2 Le passager doit impérativement fournir au transporteur, lors de la réservation ou au plus tard lors de l'achat, un numéro de téléphone mobile joignable dans le pays d'origine et le pays de destination et une adresse électronique o&ugrave; il peut être contacté. Si la réservation est effectuée par un agent accrédité, le passager accepte que ces informations soient communiquées par l'agent accrédité au transporteur. <a>Si ces informations n'ont pas été communiquées au transporteur et/ou à l'agent accrédité, le passager ne pourra pas bénéficier de son droit à l'information en cas d'irrégularités de vol.&nbsp;</a></h4>
<h4>5.3.3 Toutes les données personnelles du passager sont collectées et traitées par Tassili Airlines conformément à la charte de protection des données personnelles.</h4>
<p className="text-justify"><a href="https://www.tassiliairlines.dz/charte-pdp">https://www.tassiliairlines.dz/charte-pdp</a></p>
<h3><strong>5.4 </strong><strong>&nbsp;Attribution de siège&nbsp;:</strong></h3>
<p className="text-justify">Le transporteur s'efforce d'honorer les demandes de places préalables, mais ne peut garantir l'attribution d'un siège donné, même si la réservation est confirmée pour ledit siège. Le transporteur se réserve le droit de modifier l'attribution des sièges à tout moment, y compris après l'embarquement pour des raisons liées à l'exploitation, à la sécurité ou à la sûreté, ou en raison d'un cas de force majeure.&nbsp;</p>
<h3><strong>5.5 </strong><strong>&nbsp;Frais de service pour siège non occupé&nbsp;:</strong></h3>
<p className="text-justify">Des frais de service, conformément à la réglementation du transporteur, peuvent être payables par un passager qui n'utilise pas l'espace pour lequel une réservation a été effectuée.</p>
<h3><strong>5.6 </strong><strong>&nbsp;Annulation des réservations ultérieures effectuées par le transporteur&nbsp;: </strong></h3>
<p className="text-justify">Si un passager n'utilise pas une réservation et oublie d'informer le transporteur, celui-ci peut annuler ou demander l'annulation de toute réservation de continuation ou de retour.</p>
<h3><strong>ARTICLE 06- ENREGISTREMENT ET EMBARQUEMENT</strong></h3>
<h3>6.1 Les délais d'enregistrement et l'heure limite d'enregistrement sont définis en fonction du type d'avion, du réseau, des horaires adaptés, des chargements et des particularités prévus, tout en respectant les droits des passagers et les exigences de départs à temps. Le transporteur ou son agent accrédité fourniront les informations nécessaires sur l'heure limite d'enregistrement.</h3>
<h3>6.2 &nbsp;Le passager doit arriver aux guichets d'enregistrement et à la porte d'embarquement du transporteur suffisamment avant le départ du vol pour permettre l'accomplissement de toutes les formalités gouvernementales et des procédures de départ et de tout événement au plus tard à l'heure indiquée par le transporteur ou son agent accrédité.</h3>
<h3>6.3 &nbsp;Si le passager n'arrive pas à temps au point d'enregistrement ou à la porte d'embarquement du transporteur ou s'il ne présente pas les documents nécessaires à l'enregistrement et que le passager se trouve dans l'incapacité de voyager, le transporteur peut annuler la réservation du passager, sans aucune responsabilité envers le passager.</h3>
<h3>6.4 &nbsp;Le transporteur n'est pas responsable envers le passager des pertes ou des dépenses dues au non-respect par le passager des dispositions du présent article.</h3>
<h3><strong>ARTICLE 07-</strong> <strong>REFUS ET LIMITATION DU TRANSPORT </strong></h3>
<h3> <strong>Droit de refuser le transport </strong></h3>
<p className="text-justify">Le transporteur peut refuser le transport de tout passager et/ou de ses bagages pour des raisons de sécurité ou si&nbsp;: </p>
<h3>7.1.1 Le passager ne se conforme pas à toutes les lois gouvernementales applicables, aux règlementations, à tout point d'embarquement et/ou de transit.</h3>
<h3>7.1.2 Les passagers appartenant aux catégories citées ci-après&nbsp;:</h3>
<p className="text-justify">L'état physique ou mental du passager dont le comportement risque de mettre en péril la sécurité du vol, la santé et le confort du passager lui-même ou celui des autres passagers.</p>
<p className="text-justify">Personnes névrosées.</p>
<p className="text-justify">Personnes atteintes de maladies contagieuses, éruptives ou nauséabondes.</p>
<p className="text-justify">Personne à un degré d'infirmité physique tel que le voyage qui risque d'entra&icirc;ner des complications.</p>
<p className="text-justify">La personne a besoin de soins infirmiers ou de soins individuels pendant le vol et qui n'est pas accompagnée d'un accompagnateur qualifié.</p>
<p className="text-justify">Des informations sont requises sur l'état de santé du passager (diagnostic) lorsque son propre médecin refuse de divulguer ces informations au service médical de la compagnie.</p>
<p className="text-justify">Personnes cardiaques.</p>
<p className="text-justify">Personnes en état d'ébriété ou sous influence de stupéfiants.</p>
<p className="text-justify">Personne qui refuse de se soumettre aux conditions spécifiques de transport prévues par Tassili Airlines.</p>
<p className="text-justify">Personne qui ne peut pas utiliser le siège standard.</p>
<h4>7.1.3 Le passager requiert une assistance spéciale au transporteur.</h4>
<h4>7.1.4 Le passager ne respecte pas les instructions du transporteur.</h4>
<h4>7.1.5 Le passager refuse de se soumettre à un contr&ocirc;le de sécurité.</h4>
<h4>7.1.6 Le passager (ou la personne qui paye le billet) refuse de payer le tarif applicable, les frais, les taxes ou les accords de crédit convenus entre le transporteur et le passager.</h4>
<h4>7.1.7 Le passager ne présente pas les documents nécessaires à l'enregistrement et au voyage.</h4>
<h4>7.1.8 Le passager cherche à pénétrer illégalement dans un territoire lors d'un transit.</h4>
<h4>7.1.9 &nbsp;Le passager détruit ses documents de voyage durant le vol.</h4>
<h4>7.1.10 &nbsp;Le passager refuse de remettre les documents de voyage à la demande de l'équipage.</h4>
<h4>7.1.11 Le billet que présente le passager&nbsp;:</h4>
<h4>7.1.11.1 Est acquis illégalement, acheté auprès d'une entité autre que le transporteur ou son agent accrédité. </h4>
<h4>7.1.11.2 &nbsp;Est signalé comme perdu ou volé.</h4>
<h4>7.1.11.3 &nbsp;Est contrefait.</h4>
<h4>7.1.11.4 &nbsp;Le non-respect de l'utilisation du billet dans l'ordre séquentiel des coupons.</h4>
<h4>7.1.12 &nbsp;Le passager n'est pas en mesure de prouver qu'il est la personne désignée sur le billet.</h4>
<p className="text-justify">7.1.13 Susception de transaction de paiement <a>frauduleuse</a></p>
<p className="text-justify">7.1.14 Le passager a commis auparavant au moins un des actes cités plus haut.</p>
<h3><strong>ARTICLE 08-</strong> <strong>ASSISTANCE PARTICULIERE </strong></h3>
<h3>8.1 L'acceptation du transport des passagers à particularités nécessite une assistance distinctive. Tassili Airlines peut accepter le transport de ces passagers cités ci-après sous réserve de certaines conditions.</h3>
<p className="text-justify">Enfants non accompagnés.</p>
<p className="text-justify">Femmes enceintes.</p>
<p className="text-justify">Passager avec bébé.</p>
<p className="text-justify">Passager à mobilité réduite PMR.</p>
<p className="text-justify">Passager malades.</p>
<p className="text-justify">Passager sur civière STCR.</p>
<p className="text-justify">Passager ayant besoin d'une assistance oxygène</p>
<h3>8.2 &nbsp;Le passager doit avertir le transporteur de son cas ou de toute demande d'assistance particulière au moment de la réservation et au plus tard 72 heures avant le départ.</h3>
<h3>8.3 &nbsp;Si une demande d'assistance particulière est faite après la réservation ou, selon la réglementation applicable, moins de 72&nbsp;h avant le départ, le transporteur mettra tout en &oelig;uvre pour la satisfaire conformément à la réglementation applicable, compte tenu notamment du délai imparti et des spécificités de l'assistance demandée. Les modalités particulières relatives au transport des personnes telles que mentionnées au présent article sont disponibles sur le lien&nbsp;: <a href="Conditions%20d'acceptation%20des%20passagers%20à%20particularités.docx">Conditions d'acceptation des passagers à particularitès.docx</a> ou auprès des agents accrédités par le transporteur.</h3>
<h3><strong>ARTICLE 09-</strong> <strong>BAGAGES </strong></h3>
<h2><strong>9.1 </strong><strong>&nbsp;Franchise de bagage&nbsp;:</strong></h2>
<p className="text-justify">Les passagers ont droit à une franchise de bagages enregistrés prédéterminée qui peut varier en fonction du tarif payé, de la catégorie de passager, de l'itinéraire, du statut du groupe ou de la classe. La franchise transcrite sur le billet du passager doit être respectée. Ainsi qu'une franchise de bagages non enregistré transportée en cabine, limitée par le poids et le type d'articles.</p>
<h2><strong>9.2 </strong><strong>&nbsp;Objet non admis&nbsp;:</strong></h2>
<h4>9.2.1 Le passager ne doit pas inclure dans ses bagages&nbsp;:</h4>
<h4>9.2.1.1 Les objets susceptibles de constituer un danger pour l'aéronef, les personnes ou les biens à bord, comme ceux qui sont spécifiés dans la réglementation sur les Matières Dangereuses de l'Organisation de l'Aviation Civile Internationale (OACI) et de l'Association Internationale des Transporteurs Aériens (IATA) et dans les présentes Conditions Générales de Transport, il s'agit notamment des explosifs, gaz sous pression, substances oxydantes, radioactives ou magnétisées, substances inflammables, substances toxiques ou corrosives.</h4>
<h4>9.2.1.2 Les articles que le transporteur considère comme impropres au transport en raison de leur poids, taille, forme ou caractère, ou qui sont fragiles ou périssables sous réserve d'un accord préalable du transporteur.</h4>
<h4>9.2.2 Les fusils, armes à feu, armes pointues, objets tranchants, instruments contondants, matières explosives et hautement inflammables, produits chimiques. Ce type d'objet ne peut, en aucun cas, être transporté en cabine. Ils peuvent néanmoins être inclus dans les bagages enregistrés, sous réserve de l'acceptation du transporteur et en application de la réglementation en vigueur.</h4>
<h4>9.2.3 Les devises, les bijoux, les objets d'art, les métaux précieux ou autres objets de valeur. Il est à ce titre rappelé qu'en cas de destruction, de perte ou d'avarie de bagage enregistré, Tassili Airlines ne sera pas responsable que dans les limites définies par la réglementation en vigueur.</h4>
<h3><strong>9.3 </strong><strong>&nbsp;Droit d'inspection </strong></h3>
<p className="text-justify">Pour des raisons de sûreté et de sécurité, le passager peut être sollicité par le transporteur ou par les autorités afin de procéder à une fouille ou à un contr&ocirc;le de type rayons X ou autre de ses bagages. Si le passager n'est pas disponible, ses bagages peuvent être fouillés en son absence, en vue notamment de vérifier s'ils contiennent des objets visés à l'article 9.2.2.</p>
<p className="text-justify">Si le passager refuse de consentir une telle demande, le transporteur peut refuser de le transporter ainsi que ses bagages.</p>
<p className="text-justify">Dans le cas d'un contr&ocirc;le au rayon X ou d'un autre examen causant des dommages au passager ou à ses bagages, le transporteur n'est pas responsable de ces dommages, sauf en cas de faute ou de négligence de la part du transporteur.</p>
<h3><strong>9.4 </strong><strong>Droit de refuser le transport des bagages&nbsp;</strong></h3>
<h4>9.4.1 Le transporteur refuse de transporter comme bagages les articles décrits à l'article 9.2, Le transporteur n'a aucune obligation de prendre en dép&ocirc;t les bagages et/ou les articles refusés au transport.</h4>
<h4>9.4.2 Le transporteur peut refuser de transporter des bagages enregistrés qui ne sont pas emballés correctement ou qui ne sont pas adaptés au transport aérien en raison de leur poids, de leur taille ou de leur nature.</h4>
<h4>9.4.3 Le transporteur peut refuser de transporter les bagages pour lesquels le passager a refusé de payer le supplément tarifaire tel que défini à l'article 9.6. Le transporteur n'a aucune obligation de prendre en dép&ocirc;t des bagages et/ou des articles refusés au transport.&nbsp;</h4>
<h4>9.4.4 Le transporteur peut refuser de transporter en soute les bagages qui n'ont pas été remis par le passager au transporteur avant l'heure limite d'enregistrement.</h4>
<h4>9.4.5 Le poids maximum admissible par bagage est de 32 kg, au-delà de cette franchise, Tassili Airlines refuse le transport de ce bagage.</h4>
<h4> Le transporteur n'accepte pas le transport des animaux dépourvus des documents requis par la réglementation applicable, tels que définis à l'article 9.9</h4>
<h3><strong>9.5 </strong><strong>Bagage de Soute (bagage enregistré)&nbsp;</strong></h3>
<h4>9.5.1 Un bagage enregistré est un bagage accepté par Tassili Airlines pour être chargé en soute.</h4>
<h4>9.5.2 Un bagage enregistré est un bagage pour lequel Tassili Airlines prend la garde et émet une étiquette d'identification de bagage pour chaque pièce de bagage enregistré.</h4>
<h4>9.5.3 Le passager doit apposer ses coordonnées personnelles sur ses bagages enregistrés.</h4>
<h4>9.5.4 Les bagages enregistrés sont transportés dans la soute de l'aéronef sur lequel le passager voyage.</h4>
<h3><strong>9.6 </strong><strong>Excédent de bagage&nbsp;:</strong></h3>
<p className="text-justify">Tout bagage dépassant la franchise prédéfinie à l'article 9.1 engage des frais d'excédent. Ces frais sont appliqués au moment de l'acceptation du bagage.</p>
<h3><strong>9.7 </strong><strong>Retrait et livraison des bagages enregistrés</strong></h3>
<h4>9.7.1 Le passager doit récupérer son bagage enregistré dès qu'il est mis à sa disposition à destination ou en escale. Si le passager ne le récupère pas dans un délai raisonnable, le transporteur peut facturer des frais de stockage.</h4>
<h4>9.7.2 Si le passager ne récupère pas son bagage enregistré dans un délai de trois (3) mois à compter de sa mise à disposition, le transporteur peut en disposer sans aucune responsabilité envers le passager.</h4>
<h4>9.7.3 Seul le porteur du tag de bagages et/ou de l'étiquette d'identification du bagage a le droit de retirer le bagage enregistré.</h4>
<h3><strong>9.8 </strong><strong>Bagage de Cabine (bagage non enregistré)</strong></h3>
<h4>9.8.1 Les bagages de cabine sont des bagages qui sont transportés et rangés dans la cabine sous le contr&ocirc;le et la garde du passager. Il est communément appelé bagage à main ou bagage non enregistré.</h4>
<h4><strong>9.8.2 </strong><strong>Types de bagages de cabine accepté au transport&nbsp;:</strong></h4>
<p className="text-justify">Bagages transportés dans les limites de la franchise de bagages de cabine de Tassili Airlines.</p>
<p className="text-justify">En plus de l'allocation standard, Tassili Airlines autorise les articles de cabine gratuits, tels que&nbsp;: un sac à main, un ordinateur portable, un article hors taxe,&lt;span '&gt; un manteau, un parapluie ou un appareil photo.</p>
<p className="text-justify">Articles spéciaux pouvant nécessiter un arrangement préalable, une notification et / ou une vérification spécialisée ou des frais supplémentaires tels que&nbsp;: les instruments de musique, les animaux domestiques&hellip;etc.</p>
<p className="text-justify">Les bagages volumineux, fragiles ou précieux tels que les instruments de musique, les tableaux d'art, les trophées, peuvent être acceptés à bord, placés sur un siège (extra-seat), sous réserve d'un accord préalable de Tassili Airlines et du paiement d'un supplément tarifaire</p>
<p className="text-justify">Des articles de marchandises dangereuses autorisés dans les bagages de cabine, y compris ceux qui nécessitent une approbation préalable de Tassili Airlines</p>
<ul>
<li>Pour des raisons de sécurité, de nombreux liquides, aérosols et de gels sont limités pour le transport dans les bagages à main. </li>
</ul>
<h4>9.8.3 Le passager est autorisé à transporté un seul bagage en cabine, dont les trois (3) dimensions ne dépassent pas <strong>cent quinze (115) centimètres (55 + 35 + 25).</strong> Ces dimensions s'appliquent à toute la flotte de Tassili Airlines et correspondent aux gabarits à bagage de la compagnie.</h4>
<h4>9.8.4 Le poids de bagage de cabine ne doit pas dépasser 10 kg en classe économique et 12 kg en la classe affaire.</h4>
<h4>9.8.5 Le bagage de cabine doit être placé dans les compartiments bagage prévue à cet effet de manière à ne pas gêner la circulation dans les allées, les portes et les issues de secours.</h4>
<h4>9.8.6 Dans le cas o&ugrave; le bagage de cabine est placé sur un siège à cause de son volume, disponibilité et préciosité, il doit être sécurisé par une ceinture et emballer ou couvert de manière à éviter d'éventuelles blessures aux passagers et aux membres de cabine.</h4>
<h4>9.8.7 Si le bagage à main dépasse la taille et / ou le poids de l'allocation gratuite, il doit être enregistré en soute, moyennant des frais.</h4>
<h4><strong>9.8.8 </strong>Les articles susceptibles de présenter un danger durant le vol ne sont pas acceptés dans les bagages à main et doivent obligatoirement être mis dans les bagages de soute, Ces articles sont : ciseaux, couteaux, limes à ongles, canifs, lames de rasoir, aiguille à tricoter, outils professionnels, objets ou simulant à des armes (jouets). Si ces articles sont détectés par les services de sureté lors de l'embarquement, ils seront retirés aux passagers et ne sont en aucun cas ni embarqués ni restitués aux passagers. <strong>Cette instruction doit être impérativement respectée, et appliquée, aucune dérogation à cette règle ne sera autorisée</strong></h4>
<h4>9.8.9 Le passager est responsable des effets personnels et des bagages non enregistrés qu'il conserve en cabine. En cas de destruction, de vol, de perte ou d'avarie des effets personnels et des bagages non enregistrés, la responsabilité du transporteur ne peut être engagée sauf en cas de faute ou de négligence de la part du transporteur.</h4>
<h3>9.9 &nbsp;De plus amples informations sur les objets dangereux et matières interdites non acceptés au transport aérien, sont disponibles sur le lien suivant:</h3>

<a href="/bagagerestriction"><h4>Informations sur les objets dangereux et matières interdites.</h4></a>

<h3><strong>9.10 </strong><strong>Bagage à particularité</strong></h3>
<p className="text-justify">Certains bagages portent un titre de particularité, l'acceptation de cette catégorie de bagages tels que cité ci-après est soumise à des conditions : </p>
<p className="text-justify">Batteries. </p>
<p className="text-justify">Appareils électroniques portable APE. </p>
<p className="text-justify">Bicyclettes. </p>
<p className="text-justify">Poussettes repliables ou landaus pour bébés</p>
<p className="text-justify">Chaises roulantes des passagers.</p>
<p className="text-justify">Béquilles, appareils orthopédiques et autres dispositifs pour handicapés</p>
<p className="text-justify">Equipements de Sport (plongée, ski, surf&nbsp;&hellip;). </p>
<p className="text-justify">Instruments de musique.</p>
<p className="text-justify">Un chien de mal voyant.</p>
<p className="text-justify">Les modalités particulières relatives à l'acceptation du bagage à particularité telles que mentionnées au présent article sont disponibles sur le lien&nbsp;: <a href="Bagage%20à%20particularité.docx">Bagage à particularité.docx</a></p>
<h3><strong>9.11 </strong><strong>Transport des animaux vivants </strong></h3>
<h4>9.11.1 Les animaux sont admis au transport aérien soit en soute (AVIH&nbsp;: Live Animal In Hold), soit exceptionnellement en cabine (PETC&nbsp;: PET in Cabine).</h4>
<h4>9.11.2 Les animaux vivants sont admis au transport aérien en soute et/ou en cabine sous réserve de satisfaire aux exigences légales de transports, de transit et d'entrée dans les pays de destination conformément aux normes IATA et à l'accord préalable du transporteur.</h4>
<h4>9.11.3 Le nombre d'animaux vivants pouvant être transportés est limité par type d'aéronef et par passager.</h4>
<h4>9.11.4 L'animal doit satisfaire aux conditions phytosanitaires d'acceptation du pays de destination et de transit. Suivant les pays, sont exigés les documents suivants&nbsp;:</h4>
<p className="text-justify">Le certificat d'origine attestant que le pays ou la région d'origine de l'animal est resté libre de toute maladie contagieuse pendant un délai déterminé.</p>
<p className="text-justify">Le certificat vétérinaire de bonne santé datant de moins de cinq (05) jours à la date du voyage.</p>
<p className="text-justify">Le certificat d'autorisation des services phytosanitaires du pays de destination.</p>
<p className="text-justify">Le certificat de vaccinations.</p>
<h4>9.11.5 Le propriétaire ou le passager voyageant avec un chien doit être majeur.</h4>
<h4>9.11.6 Les chiens d'attaque et les chiens dits de garde ou de défense ; les animaux infectés sont interdits au transport (en cabine, comme en soute).</h4>
<h4>9.11.7 Le transporteur ne peut être tenu responsable des blessures ou de la perte, de la maladie ou de la mort d'un animal.</h4>
<h4>9.11.8 Le transport des animaux vivants n'est pas compris dans la franchise de bagage, est soumis à un supplément tarifaire dont les conditions sont disponibles auprès du transporteur.&nbsp;</h4>
<h4>9.11.9 Les chiens d'assistance ainsi que leur caisse accompagnant les passagers à mobilité réduite sont transportés gratuitement, en sus de la franchise de bagages, conformément aux règles du transporteur.&nbsp;</h4>
<h4><strong>9.11.10 </strong><strong>Acceptation des animaux vivants en Cabine </strong></h4>
<p className="text-justify">Seuls les animaux de compagnie et de petite taille dont le poids ne dépasse pas cinq (5) kg peuvent voyager en cabine sous réserve de l'accord préalable de la compagnie.</p>
<p className="text-justify">Les chiens d'assistance sont acceptés en cabine, sous réserve de la réglementation applicable.</p>
<p className="text-justify">Les animaux vivants transportés en cabine doivent être mis dans un contenant approprié ou un sac étanche muni d'un système de fermeture approprié et de trous d'aération.</p>
<p className="text-justify">Les dimensions du contenant ou du sac étanche ne dépassent pas 45cm X 35cm X 20cm.</p>
<p className="text-justify">L'animal doit être maintenu dans le contenant et rester sous la responsabilité du propriétaire pendant toute la durée du vol.</p>
<h3><strong> </strong><strong>Acceptation des animaux vivants en Soute </strong></h3>
<p className="text-justify">Seuls les conteneurs rigides avec une porte sécurisée sont acceptables.</p>
<p className="text-justify">Un réservoir d'eau doit être fourni dans chaque contenant. </p>
<p className="text-justify">Le conteneur doit être suffisamment grand pour permettre à l'animal de se tenir dans une position naturelle, de se retourner et de s'allonger.</p>
<h3><strong>ARTICLE 10- IRREGULARITES DE VOL (RETARDS, ANNULATION) </strong></h3>
<h3> <strong>Généralités </strong></h3>
<h4><strong>10.1.1 </strong>&nbsp;Les vols et les horaires de vol indiqués dans les indicateurs horaires n'ont pas de valeur contractuelle et ont uniquement pour vocation d'informer le passager des vols proposés par le transporteur. Ces indicateurs horaires ne sont pas définitifs et sont susceptibles d'être modifiés après la date de leur publication. </h4>
<h4> En revanche, les horaires des vols mentionnés sur le billet sont réputés, sous réserve de modification pour des motifs indépendants de la volonté du transporteur, faire partie intégrante du contrat de transport. </h4>
<h4> Il appartient au passager de communiquer au transporteur ses coordonnées afin qu'il puisse être contacté et bénéficier de son droit à l'information en cas de changement d'horaires programmés tels que mentionnés sur le billet.</h4>
<h4> Les aléas d'exploitation peuvent générer des modifications au programme établi avec des conséquences sur les conditions de voyage des passagers. </h4>
<h4> La ponctualité est le paramètre essentiel de la qualité de service. Un vol à l'heure, c'est l'exécution conforme du programme. Tassili Airlines s'efforce de prendre toutes les mesures nécessaires pour transporter sans retard les passagers et leurs bagages.</h4>
<h4> Un vol est réputé en retard lorsque son heure de départ dépasse l'heure programmée. </h4>
<h4> Les causes du retard peuvent être endogènes ou exogènes telles que définies à l'article 01.</h4>
<h4> Tassili Airlines peut refuser l'embarquement des passagers sur l'un de ses vols pour l'une des raisons suivantes&nbsp;: </h4>
<p className="text-justify">Annulation ou retard d'un vol.</p>
<p className="text-justify">Annulation d'une escale intermédiaire.</p>
<p className="text-justify">Déroutement d'un vol.</p>
<p className="text-justify">Important retard au départ.</p>
<p className="text-justify">Survente/Surbooking.</p>
<p className="text-justify">Changement d'appareil par une version plus réduite.</p>
<h4> Le refus d'embarquement, l'annulation ou le retard important d'un vol pour des causes endogènes ou exogènes entra&icirc;nent des difficultés et des désagréments importants pour les passagers. La compagnie Tassili Airlines s'engage, à travers la réglementation en vigueur et sa politique, à traiter et à prendre en charge ces irrégularités convenablement, à la satisfaction du passager, <strong>sous réserve d'une réservation confirmée</strong>. </h4>
<h4>10.1.10 La compagnie n'est pas responsable des effets générés par les causes exogènes qu'elle subit au même titre que sa clientèle, mais des considérations commerciales et parfois humaines peuvent justifier la prise en charge des passagers. Le confort du passager commence par une information soutenue et sa prise en charge par une série de services en nature. La nature de la prise en charge est dictée par le moment et la durée de l'irrégularité.</h4>
<h3><strong>10.2 </strong><strong>&nbsp;Refus d'embarquement</strong></h3>
<p className="text-justify">Lorsque Tassili Airlines prévoit, pour une raison motivée, de refuser l'embarquement de passagers sur un vol, elle fait d'abord appel aux volontaires acceptant de renoncer à leur réservation, de certains avantages, suivant des modalités à convenir entre les passagers concernés et le transporteur. </p>
<p className="text-justify">Les volontaires bénéficient, en plus des prestations mentionnées à l'article 10.5, d'une assistance conformément à l'article 10.6 ou d'un remboursement selon les dispositions citées à l'article 10.7.</p>
<p className="text-justify">Lorsque le nombre de volontaires n'est pas suffisant pour permettre l'embarquement des autres passagers disposant d'une réservation confirmée, Tassili Airlines peut refuser l'embarquement de passagers contre leur volonté. Dans ce cas, les passagers peuvent faire valoir leur droit, et ce, par&nbsp;: </p>
<p className="text-justify">L'assistance prévue à l'article 10.5</p>
<p className="text-justify">Le remboursement de billets conformément à l'article 10.7.</p>
<p className="text-justify">L'indemnisation conformément à l'article 10.8.</p>
<h3><strong>10.3 </strong><strong>&nbsp;Annulation </strong></h3>
<p className="text-justify">En cas d'annulation d'un vol, les passagers concernés se voient offrir par le transporteur&nbsp;: </p>
<p className="text-justify">Le réacheminement, o&ugrave; </p>
<p className="text-justify">Le remboursement de billets conformément à l'article 10.7.</p>
<p className="text-justify">L'indemnisation conformément à l'article 10.8, à moins qu'ils soient informés de l'annulation au moins deux (02) semaines avant l'heure de départ prévue.</p>
<h4>Le transporteur n'est pas tenu de verser l'indemnisation prévue à l'article 10.8, s'il est en mesure de prouver, conformément à la législation en vigueur, que l'annulation est due à des cas de forces majeures (causes exogènes / circonstances extraordinaires).</h4>
<p className="text-justify">En cas d'annulation de vol, il est permis aux passagers :</p>
<p className="text-justify">La revalidation des billets sur des vols ultérieurs sans frais supplémentaires.</p>
<p className="text-justify">Le changement d'itinéraire sans réajustement tarifaire.</p>
<h3><strong>10.4 </strong><strong>&nbsp;Retard </strong></h3>
<p className="text-justify">Lorsque le transporteur prévoit, pour une raison motivée, qu'un vol sera retardé par rapport à l'heure de départ prévu, de&nbsp;:</p>
<p className="text-justify">Deux (2) heures ou plus pour tous les vols de moins de 1500 kilomètres, ou ;</p>
<p className="text-justify">Trois (3) heures ou plus pour tous les vols de 1500 à 3500 km, ou ;</p>
<p className="text-justify">Quatre (4) heures ou plus pour tous les vols de plus de 3500 km.</p>
<p className="text-justify">Les passagers se voient proposer par le transporteur l'assistance prévue à l'article 10.5.</p>
<p className="text-justify">Au-delà de cinq (05) heures d'attente, et sur demande, les passagers peuvent faire valoir leur droit au remboursement et ce conformément à l'article 10.7.</p>
<h3><strong>10.5 </strong><strong>&nbsp;Prestation </strong></h3>
<p className="text-justify">En cas de refus d'embarquement, d'annulation et de retards de vols, les passagers se voient offrir gratuitement, et ce, conformément à la réglementation de Tassili Airlines, aux dispositions du décret exécutif 16-175 du 14/06/2016 et du Règlement Européen (CE) Nº 261/2004&nbsp;:</p>
<p className="text-justify">Des rafraichissements et des possibilités de se restaurer en suffisance, compte tenu du délai d'attente.</p>
<p className="text-justify">Hébergement de jour et/ ou de nuit.</p>
<p className="text-justify">Le transport depuis l'aéroport jusqu'au lieu d'hébergement.</p>
<p className="text-justify">Dans le cas d'une restauration au sol, le chargement h&ocirc;telier du service à bord doit être modifié en tenant compte des prestations servies au sol.</p>
<p className="text-justify">La décision de servir des prestations doit toujours être précédée par&nbsp;:</p>
<p className="text-justify">L'analyse de l'irrégularité.</p>
<p className="text-justify">La considération de l'horaire et du retard pour déterminer le type de la prestation.</p>
<p className="text-justify">Le type de passager (Passager à forte contribution, VIP, Malade)</p>
<p className="text-justify"><strong>Note</strong> : sauf cas particuliers comme la fermeture de l'aéroport ou le risque de perte du créneau, le repas servi au sol ne peut pas être interrompu.</p>
<p className="text-justify">En outre, les passagers se voient proposer la possibilité d'effectuer gratuitement deux appels téléphoniques ou d'envoyer gratuitement deux télex, deux télécopies ou deux messages électroniques.</p>
<h3><strong>10.6 </strong><strong>&nbsp;Réacheminement </strong></h3>
<p className="text-justify">En cas de refus d'embarquement, d'annulation et de retard de vols, les passagers se voient proposer entre&nbsp;:</p>
<p className="text-justify">Un réacheminement vers leur destination finale, dans des conditions de transport comparables et dans les meilleurs délais, o&ugrave; ; </p>
<p className="text-justify">Un réacheminement vers leur destination finale dans des conditions de transport comparables à une date ultérieure, à leur convenance, sous réserve de la disponibilité de sièges.</p>
<h3><strong>10.7 </strong><strong>&nbsp;Remboursement </strong></h3>
<p className="text-justify">Les passagers peuvent rembourser leurs billets aux prix auxquels ils ont été achetés, pour la ou les parties du voyage non effectuées et pour la ou les parties du voyage déjà effectuées et devenues inutiles par rapport à leur plan de voyage initial, ainsi que, le cas échéant, un vol retour vers leur point de départ initial dans les meilleurs délais.</p>
<p className="text-justify">Les passagers peuvent faire valoir,&nbsp;et ce, dans un délai de trente (30) jours, le remboursement de leurs billets moyennant la présentation de l'originale de l'attestation de refus d'embarquement délivrée par le personnel de l'escale de départ ou par le représentant dûment mandaté par Tassili Airlines.</p>
<p className="text-justify">La demande de remboursement doit être accompagnée de l'attestation originale de refus d'embarquement, dûment signée et visée par le cachet humide de l'escale de départ, et introduite, selon le cas, comme suit&nbsp;:</p>
<div>
<table width="611" >
<tbody>
<tr>
<td>
<p className="text-justify"><strong>Billet acheté&nbsp;sur le site </strong><a href="http://www.tassiliairlines.dz"><strong>www.tassiliairlines.dz</strong></a></p>
</td>
<td>
<p className="text-justify">Envoyer un Email à&nbsp;<a href="mailto:epay@tassiliairlines.com">epay@tassiliairlines.com</a></p>
</td>
</tr>
<tr>
<td>
<p className="text-justify"><strong>Billet acheté dans une agence</strong></p>
<p className="text-justify"><strong>TASSILI AIRLINES</strong></p>
</td>
<td>
<p className="text-justify">S'adresser à cette même agence</p>
</td>
</tr>
<tr>
<td>
<p className="text-justify"><strong>Billet acheté dans une agence agréée par TASSILI AIRLINES</strong></p>
</td>
<td>
<p className="text-justify">Adresser votre requête à cette même agence</p>
</td>
</tr>
</tbody>
</table>
</div>
<p className="text-justify">Le remboursement doit intercéder dans un délai de huit (08) jours à compter de la date d'introduction de la demande auprès de Tassili Airlines et doit se faire par l'agence émettrice du billet contre la présentation de l'attestation d'annulation dument signé et visée par le cachet humide l'escale de départ.</p>
<h3><strong>10.8 </strong><strong>&nbsp;Indemnisation </strong></h3>
<p className="text-justify">En cas de refus d'embarquement ou d'annulation de vols, les passagers re&ccedil;oivent une indemnisation forfaitaire dont le montant est fixé à&nbsp;:</p>
<p className="text-justify">a) <strong>3000 DZD</strong> pour les vols nationaux</p>
<p className="text-justify">b) <strong>4500 DZD</strong> pour les vols internationaux</p>
<h4><strong>10.8.1 </strong><strong>Processus d'indemnisation </strong></h4>
<p className="text-justify">Le passager prétendant à indemnisation doit adresser une demande par e-mail à l'adresse suivante&nbsp;: <a href="mailto:indemnisation.passagers@tassiliairlines.com">indemnisation.passagers@tassiliairlines.com</a>. </p>
<p className="text-justify">La demande d'indemnisation doit être accompagnée du scan des documents suivants&nbsp;:</p>
<p className="text-justify">Une copie de l'Attestation d'Annulation ou de Refus d'embarquement.</p>
<p className="text-justify">Une copie du billet confirmé.</p>
<p className="text-justify">Une copie du talon de la carte d'embarquement, si disponible.</p>
<p className="text-justify">Une copie de l'attestation de remboursement.</p>
<p className="text-justify">Le justificatif que le passager n'a bénéficié d'aucune autre indemnisation sous quelque forme que ce soit pour cette même réclamation.</p>
<p className="text-justify">Une copie de la carte d'identité nationale ou passeport.</p>
<p className="text-justify">Un numéro de compte valide&nbsp;: Relevé d'Identité Bancaire (RIB) ou Relevé d'identité Postale (RIP).</p>
<p className="text-justify">Le préposé doit confirmer que les passagers ayant introduit une demande d'indemnisation n'ont pas bénéficié d'aucune indemnisation de quelque nature que ce soit.</p>
<p className="text-justify">Une fois la demande traitée et accordée, la compagnie procédera à un virement sur le compte communiqué par le passager concerné par l'indemnisation.</p>
<h3><strong>10.9 </strong><strong>Irrégularités au départ d'un pays membre de l'Union Européenne </strong></h3>
<p className="text-justify">En cas de refus d'embarquement, d'annulation de vol (à moins que le passager soit informé de l'annulation de vol deux semaines à l'avance), et de retard de plus de trois (03) heures, outre le droit au remboursement du billet, et sous réserve de ne pas avoir bénéficié d'aucune autre indemnisation ou prise en charge de quelque nature que ce soit, le passager ouvre le droit à une indemnisation comme suit&nbsp;:</p>
<p className="text-justify"> 250 Euros pour tous les vols de moins de 1500 Km.</p>
<p className="text-justify"> 400 Euros pour tous les vols de 1500 Km et les vols de 1500 Km à 3500 Km.</p>
<p className="text-justify"> 600 Euros pour tous les vols qui ne relèvent pas des points (1) et (2).</p>
<p className="text-justify">Et ce, en application du Règlement Européen Nº261/200 du 11/02/2004 et de la jurisprudence européenne (Arrêt Strugeon, aff. C-402/07 et C-432/07 du 19 Novembre 2009 Arrêt Nelson, aff. C-58/10 et C-629/10 du 23 Octobre 2012). </p>
<h3>10.10 Tassili Airlines veille aux besoins de toute personne à mobilité réduite, conformément aux dispositions des articles 173 quater, 173 quinquies et 173 sexies de la loi nº 98-06 du 3 Rabie El Aouel 1419 correspondant au 27 juin 1998, susvisée, et de toute personne qui l'accompagne, ainsi qu'aux besoins des enfants non accompagnés</h3>
<h4> Les distances indiquées aux articles 10.4 et 10.10 sont mesurées selon la méthode de la route orthodromique.</h4>
<h4><strong>10.12 </strong><strong>Modifications involontaires d'acheminement des passagers</strong></h4>
<p className="text-justify">Une autre irrégularité que le refus d'embarquement, l'annulation et le retard des vols, peut se déclencher, c'est l'acheminement involontaire des passagers. </p>
<h4>10.12.1 Deux types de modification involontaire d'acheminement sont définis :</h4>
<p className="text-justify">Avant le départ de l'avion, avion au sol</p>
<p className="text-justify">Après le départ de l'avion, passagers en vol</p>
<p className="text-justify">Les modifications d'acheminement, indépendantes de la volonté du passager, sont généralement causées par :</p>
<p className="text-justify">Annulation ou retard d'un vol.&nbsp;</p>
<p className="text-justify">Annulation d'une escale intermédiaire.</p>
<p className="text-justify">Déroutement d'un vol.</p>
<p className="text-justify">Important retard au départ.</p>
<p className="text-justify">Correspondance ratée.</p>
<p className="text-justify">Survente/Surbooking.</p>
<p className="text-justify">Changement d'appareil par une version plus réduite.</p>
<h4> Les UM ne font pas l'objet de modifications d'itinéraire, mais si exceptionnellement le réacheminement s'imposait, l'accompagnement de bout en bout par un agent de la compagnie est obligatoire (sauf pour ceux qui sont accompagnés).</h4>
<h4>10.12.3 L'acheminement des passagers peut avoir lieu par&nbsp;:</h4>
<p className="text-justify">Acheminement sur le vol reporté</p>
<p className="text-justify">Acheminement par un vol ultérieur</p>
<p className="text-justify">Acheminement par un itinéraire différent</p>
<p className="text-justify">Acheminement sur un vol d'une autre compagnie sans changement d'itinéraire</p>
<p className="text-justify">Acheminement sur un vol d'une autre compagnie avec changement d'itinéraire</p>
<h4>10.12.4 Dans le cas o&ugrave; les passagers sont acheminés par l'une des dispositions citées à l'article 10.12.3, le transporteur doit :</h4>
<p className="text-justify">Informer les passagers de la situation en temps réel.</p>
<p className="text-justify">Proposer aux passagers les solutions possibles et recueillir leurs accords.</p>
<p className="text-justify">Solliciter les passages de la classe affaire et recueillir leurs accords en cas de déclassement.</p>
<h4><strong>10.12.5 </strong><strong>Surclassement et déclassement</strong></h4>
<p className="text-justify">Dans le cas de surclassement</p>
<p className="text-justify">&nbsp; Le passager surclassé involontairement ne paiera aucun supplément.</p>
<p className="text-justify">Dans le cas d'un déclassement</p>
<p className="text-justify">Le passager déclassé involontairement conserve le droit à la franchise de poids des bagages liée au tarif de son billet et au remboursement de la différence des tarifs des deux classes de voyage. </p>
<h3><strong>ARTICLE 11-</strong> <strong>REMBOURSEMENT </strong></h3>
<h3> Le remboursement intégral ou partiel d'un billet se fait selon les modalités définies au présent article 11, conformément aux conditions tarifaires du billet et, en tout état de cause, à la réglementation applicable en la matière.</h3>
<h3>11.2 Le remboursement est effectué auprès de l'agence qui a procédé à l'émission du billet.</h3>
<h3>11.3 Le remboursement des billets achetés en ligne sur le site https://tassiliairlines.dz, se fait à travers l'adresse mail&nbsp;: <a href="mailto:epay@tassiliairlines.com">epay@tassiliairlines.com</a></h3>
<h3> Sauf disposition contraire du présent article, le remboursement se fait soit à la personne désignée sur le billet, soit à la personne ayant payé le billet, sur présentation d'une preuve satisfaisante de ce paiement.</h3>
<h4> Si un billet est payé par une personne autre que le passager nommé dans le billet et que le billet indique qu'il existe une restriction de remboursement, le transporteur procède à un remboursement uniquement à la personne qui a payé le billet ou à la commande de cette personne.</h4>
<h3><strong>11.6 </strong><strong>&nbsp;Remboursement involontaire</strong></h3>
<p className="text-justify">Si le transporteur refuse l'embarquement d'un passager pour l'une des raisons citées dans le paragraphe 10.2, le passager peut faire valoir son droit au remboursement intégral et sans frais de son billet conformément à l'article 10.7.</p>
<h2><strong>11.7 </strong><strong>Remboursement volontaire</strong></h2>
<h4>11.7.1 Le passager a le droit au remboursement de son billet pour des raisons autres que celles exposées dans l'article 10.2. Le montant du remboursement sera calculé comme suit :</h4>
<h4>Si aucune partie du billet n'a été utilisée, un montant égal au tarif payé, moins tous les frais de service ou frais d'annulation applicables.</h4>
<h4>Si une partie du billet a été utilisée, le remboursement sera d'un montant égal à la différence entre le tarif payé et le tarif applicable pour les déplacements entre les points pour lesquels le billet a été utilisé, déduction faite des frais de service ou d'annulation applicables.</h4>
<h4>11.7.2 Les frais de services mentionnés à l'article 1, ne sont pas remboursables.</h4>
<h4>11.7.3 La surcharge carburant n'est pas autorisée au remboursement.</h4>
<h2><strong>11.8 </strong><strong>&nbsp;Droit de refuser le remboursement </strong></h2>
<p className="text-justify">Le transporteur peut refuser un remboursement si&nbsp;:</p>
<h4>11.8.1 La demande est faite après l'expiration de la validité du billet.</h4>
<h4>11.8.2 Le détenteur n'a pas été admis par les autorités de destination ou de transit du parcours prévu, et si le passager a été, de ce fait, renvoyé à son point d'embarquement ou vers toute autre destination.&nbsp;</h4>
<h4>11.8.3 &nbsp;Les coupons ne sont pas utilisés dans l'ordre indiqué sur le billet.</h4>
<h2><strong>11.9 </strong><strong>Monnaie de remboursement </strong></h2>
<p className="text-justify">Le remboursement est effectué dans la même monnaie que celle utilisée pour le paiement du billet.</p>
<h2><strong>11.10 </strong><strong>Remboursement sur les comptes Carte de Crédit </strong></h2>
<p className="text-justify">Le remboursement des billets réglés par carte de crédit ne peut être effectué que sur le compte qui a servi au paiement du billet par carte.</p>
<h3><strong>ARTICLE12- CONDUITE A BORD</strong></h3>
<h2>12.1 &nbsp;A bord de l'appareil, un passager ne doit pas&nbsp;</h2>
<p className="text-justify">Assaillir, intimider, menacer ou commettre une grave imprudence qui perturbe le bon ordre ou la sécurité des personnes ou des biens.</p>
<p className="text-justify">Assaillir, intimider, menacer ou gêner l'équipage de conduite dans l'exercice de leurs fonctions ou l'empêcher de s'en acquitter.</p>
<p className="text-justify">Commettre une grave imprudence ou endommager un aéronef, son équipement ou des structures et du matériel qui lui sont accessoires, en perturbant ainsi le bon ordre et la sécurité de l'aéronef ou de ses occupants</p>
<p className="text-justify">Donner de faux renseignements mettant ainsi en danger un aéronef en vol.</p>
<p className="text-justify">Refuser de suivre les ordres ou les instructions licites donnés pour assurer l'exploitation sûre, ordonnée et efficace de l'aéronef.</p>
<h2>12.2 Toute personne pronon&ccedil;ant des menaces verbales, des insultes, fumant en cabine, utilisant du téléphone portable et refusant de suivre les consignes du personnel navigant est considérée comme personne mena&ccedil;ante et indisciplinée.</h2>
<h2>12.3 &nbsp;Si le passager ne se conforme pas aux termes du présent article, le transporteur peut être amené, conformément aux dispositions législatives et réglementaires, à prendre toute mesure adaptée et raisonnablement nécessaire. à cet effet, le transporteur pourra notamment procéder au débarquement du passager et/ou recourir à des mesures de contrainte à tout moment du vol.&nbsp;</h2>
<h2><strong>12.4 </strong><strong>Appareils Electroniques Portables A</strong><strong>PE</strong></h2>
<p className="text-justify">Pour des raisons de sécurité, Tassili Airlines peut interdire ou limiter l'utilisation à bord de l'avion de systèmes électroniques.</p>
<p className="text-justify">Les APE (appareils photographiques, téléphones cellulaires, ordinateurs portables, etc...) contenant des piles ou des batteries au lithium devraient être transportés autant que possible comme bagage cabine, si les APE ne peuvent pas être transportés en cabine en raison de leur taille les passagers doivent respecter ce qui suit : </p>
<p className="text-justify">Les APES doivent être complètement désactivés, efficacement protégés dans un emballage individuel et séparés de toute matière inflammable.</p>
<p className="text-justify">Les alarmes qui peuvent activer le périphérique doivent être désactivées. </p>
<h3><strong>ARTICLE 13- ARRANGEMENTS POUR SERVICES SUPPLEMENTAIRES</strong></h3>
<h3>13.1 Sous réserve du droit applicable, si, au cours de la conclusion du contrat de transport aérien, le transporteur convient à prendre des dispositions pour la fourniture de services supplémentaires, le transporteur n'a aucune responsabilité envers le passager pour ces services, sauf en cas de négligence de la part du transporteur dans la réalisation de tels arrangements.</h3>
<h3>13.2 Dans le cas o&ugrave; le transporteur fournit également un transport de surface, d'autres conditions peuvent s'appliquer à un tel transport de surface. Ces conditions sont disponibles à la demande du passager.</h3>
<h3>13.3 Le transporteur aérien n'est pas responsable des dommages survenus aux passagers et à ses bagages durant le transport de surface.</h3>
<h3><strong>ARTICLE 14- FORMALITES ADMINISTRATIVES</strong></h3>
<h3><strong>14.1 </strong><strong>&nbsp;Généralités </strong></h3>
<h3><strong>14.1.1 </strong>Le passager est responsable d'obtenir tous les documents de voyage et visas requis et de se conformer à toutes les lois, réglementations, ordonnances, demandes et exigences de voyage des pays à partir desquels, vers ou à travers lesquels il transite.</h3>
<h3>14.1.2 Le transporteur n'est pas responsable des conséquences pour tout passager en cas d'inobservation des obligations visées à l'article 14.1.1.&nbsp;</h3>
<h3><strong>14.2 </strong><strong>Documents de voyage</strong></h3>
<h3>14.2.1 Le passager est tenu de présenter tous les documents de sortie, d'entrée et de transit, ainsi que les documents sanitaires et autres documents requis par la réglementation en vigueur dans les états de départ, de destination et de transit. Le passager est par ailleurs tenu de permettre au transporteur de prendre copies de ces documents, si besoin.</h3>
<h3>14.2.2 Le transporteur se réserve le droit de refuser le transport, conformément à l'article 7, si le passager ne respecte pas ces exigences.</h3>
<h3><strong>14.3 </strong><strong>&nbsp;Refus d'entrée </strong></h3>
<p className="text-justify">Si l'admission à un territoire est refusée au passager, celui-ci est tenu de payer toute amende ou charge imposée par le gouvernement concerné au transporteur. Le tarif per&ccedil;u pour le transport jusqu'au point de refus ou de refus à l'entrée ne sera pas remboursé par le transporteur.</p>
<h2><strong>14.4 </strong><strong>&nbsp;Responsabilité du passager pour amendes, frais de détention, etc </strong></h2>
<p className="text-justify">Si le transporteur doit payer ou consigner le montant d'une amende ou d'une pénalité ou engager des dépenses de toutes sortes par suite de la violation, volontaire ou non, par le passager du droit en vigueur dans les états concernés, ou par suite du défaut de présentation des documents exigés, ou encore de la présentation de documents non conformes, le passager est dans l'obligation, à la demande du transporteur, rembourser les montants versés ou consignés et les dépenses occasionnés. Le transporteur peut utiliser à cette fin toute somme qui lui a été versée pour les transports non effectués ou toute somme appartenant au passager dont le transporteur est détenteur.&nbsp;Le montant des amendes et des contraventions varie d'un pays à l'autre et peut largement dépasser le prix du billet. Dans l'intérêt du passager, le transporteur recommande de veiller au respect des dispositions d'entrée sur un territoire étranger.</p>
<h2><strong>14.5 </strong><strong>&nbsp;Contr&ocirc;le douanier </strong></h2>
<p className="text-justify">Le passager peut être sollicité pour assister à l'inspection de ses bagages par la douane ou autre autorité gouvernementale. Le transporteur n'est pas responsable pour la perte ou dommage subi par le passager au cours d'un tel contr&ocirc;le ou en raison du non-respect du passager de cette exigence.</p>
<h3><strong>14.6 </strong><strong>Contr&ocirc;le de sécurité</strong></h3>
<p className="text-justify">Le passager est tenu de se soumettre aux contr&ocirc;les de sûreté (et de sécurité) exigés par les autorités gouvernementales ou aéroportuaires ainsi qu'à la demande du transporteur.&nbsp;</p>
<h3><strong>ARTICLE15-TRANSPORTS AERIENS SUCCESSIFS</strong></h3>
<h3>15.1 Le Transport Aérien à effectuer par plusieurs Transporteurs successifs, sous couvert d'un seul billet ou de plusieurs billets émis conjointement, est censé constituer aux termes de la Convention de Varsovie et de Montréal un transport unique lorsqu'il a été envisagé par les parties comme une seule opération. Les dispositions concernant ce cas de figure sont traitées à l'article 16.</h3>
<h3>15.2 Lorsque&nbsp;le transporteur est l'émetteur du billet ou celui désigné en premier sur le billet ou sur un billet émis conjointement, dans le cas d'un transport successif, le transporteur ne sera responsable que pour la partie du transport effectuée par ses propres moyens.&nbsp;</h3>
<h3><strong>ARTICLE16- RESPONSABILITE POUR DOMMAGES </strong></h3>
<h3>16.1 La responsabilité de Tassili Airlines et de chaque transporteur impliqué dans le voyage du passager sera déterminée par ses propres conditions de transport et en application du droit applicable. Le droit applicable peut comprendre la convention de Varsovie, la convention de Montréal ainsi que les lois applicables à chaque pays. Nos dispositions en matière de responsabilité sont les suivantes&nbsp;:</h3>
<h3>16.1.1 Sauf indication contraire dans les présentes conditions, le transport international est soumis aux règles des conventions de Varsovie et de Montréal.</h3>
<h3>16.1.2 Lorsque le transport n'est pas soumis aux règles des conventions de Varsovie et de Montréal, les Conditions Générales de Transport du transporteur s'appliquent.</h3>
<h3>16.1.3 Toute responsabilité incombant au transporteur pour des dommages sera réduite par toute négligence de la part du passager ayant causé ou contribué au dommage survenu conformément à la législation applicable.</h3>
<h3>16.1.4 La responsabilité du transporteur est limitée au dommage survenu au cours des transports aériens pour lesquels son code de désignation apparait sur le coupon ou le billet correspondant au vol. Lorsque le transporteur émet un billet pour une prestation de transport assurée par un autre transporteur ou lorsqu'il enregistre un bagage pour le compte d'un autre transporteur, le transporteur n'agit qu'à titre de mandataire de ce dernier. Toutefois, en ce qui concerne les bagages enregistrés, le passager dispose d'un droit de recours contre le premier ou le dernier transporteur intervenant dans son voyage.&nbsp;</h3>
<h3>16.1.5 Le transporteur ne peut être tenu responsable en cas de dommage aux bagages non enregistrés, à moins qu'un tel dommage ne résulte directement de la faute du transporteur, d'un de ses préposés ou mandataires, laquelle devra être prouvée par le passager qui l'invoque.</h3>
<h3>16.1.6 Le transporteur ne peut en aucune manière être tenu responsable pour les dommages résultant de sa conformité à toutes les dispositions légales ou règlementaires (lois, règlements, décisions, exigences et dispositions) ou de l'inobservation de ces mêmes dispositions par le passager.&nbsp;</h3>
<h3><a ></a><strong>16.2 </strong><strong>&nbsp;Responsabilités et indemnisations liées aux Bagage</strong><strong>s</strong></h3>
<p className="text-justify">Les bagages concernés par l'indemnisation sont&nbsp;:</p>
<p className="text-justify">Bagage perdu.</p>
<p className="text-justify">Bagage endommagé ou détruit</p>
<p className="text-justify">Effets spoliés</p>
<h3><a ></a><strong>16.2.1 </strong><strong>Bagage perdus</strong></h3>
<p className="text-justify">Dans le cas o&ugrave; le bagage d'un passager est perdu, Tassili Airlines procède à l'établissement d'une PIR (Property Irregularity Report) et remet au client une pochette contenant un minimum de besoin requis (tel qu'une trousse de toilette&hellip;etc) et/ou lui verse une somme de deux mille cinq cents dinars (2500,00 DA) couvrant les frais de taxi, de communication téléphonique&hellip;etc, ou vingt (20) Euros pour les escales à l'étranger.</p>
<p className="text-justify">Le client ouvre droit à une indemnisation de bagage dès que Tassili Airlines déclare officiellement et définitivement la perte de son bagage. Cette déclaration se matérialise par la mise d'une attestation de perte définitive.</p>
<p className="text-justify">L'indemnisation du client doit se faire respectivement, par chèque ou par une compensation commerciale (réduction offerte ou surclassement) bonifiée à cent (100) Dinars ou équivalent en Euros par Kilogramme de bagage, selon le choix du passager&nbsp;; l'option de la compensation commerciale doit être favorisée.</p>
<p className="text-justify">Cette indemnisation doit intervenir dans un délai ne pouvant excéder dix (10) jours à compter de la date de réception du dossier par Tassili Airlines.</p>
<p className="text-justify">En tout état de cause, le délai de traitement du dossier de perte bagage et d'indemnisation ne saurait en aucun cas dépasser les quatre-vingt-dix (90) jours à compter de la date de l'établissement de la PIR.</p>
<p className="text-justify">Les tarifs appliqués sont à hauteur de 17 DTS par kilogramme. L'indemnisation est accordée au client, dans la monnaie d'achat du billet au taux du jour de l'établissement du PIR. Dans le cas o&ugrave; le poids du bagage perdu n'est pas lisible sur le Tag, la planche tarifaire IATA est appliquée en fonction de la lettre d'inventaire des objets du passager.</p>
<h3><a ></a><strong>16.2.2 </strong><strong>Bagages endommagés ou </strong><strong>détruits</strong></h3>
<p className="text-justify">Les bagages endommagés sont ceux ayant subi une dégradation physique ou chimique entrainant une perte de leurs valeurs totale ou partielle, et ce, lors de leurs transports à bord des avions de Tassili Airlines, constatés par le passager à leur réception au point d'arrivée. Dans ce cas, le passager doit adresser une réclamation au service de litige bagage à l'arrivée.</p>
<p className="text-justify">Dès la confirmation avérée par le préposé de Tassili Airlines de cet endommagement et suite à l'établissement d'une DBR (Damaged Bagage Report), l'indemnisation sera faite sur la base d'un forfait équivalant en DTS de trois (03) kg.</p>
<p className="text-justify">Exclusion&nbsp;: Si le bagage est constaté détérioré au niveau de l'enregistrement, le passager est exclu de l'indemnisation.</p>
<h3><a></a><strong>16.2.3 </strong><strong>Indemnisation des bagages spoliés (manquement partiel)</strong></h3>
<p className="text-justify">Le passager doit adresser une réclamation à l'escale d'arrivée immédiatement après la découverte de la spoliation ou au plus tard dans un délai de 24 heures à compter de la récupération de son bagage.</p>
<p className="text-justify">La réclamation doit être, autant que possible, accompagnée de la facture d'achat de l'objet en question.</p>
<p className="text-justify">Il est à noter qu'il appartient à Tassili Airlines d'apprécier la faisabilité du remboursement de ce passager. En tout état de cause, l'indemnisation ne pourra excéder l'équivalent en DTS de trois (03) kg, le cas échéant.</p>
<h3><strong>16.3 </strong><strong>&nbsp;Dommages corporels</strong></h3>
<p className="text-justify">Aucun référentiel en interne quant aux responsabilités de la compagnie et les dommages survenus en cas de décès ou de lésion corporelle subie par un passager, lorsque l'accident qui a causé le dommage s'est produit à bord de l'aéronef ou au cours de toutes opérations d'embarquement et de débarquement.</p>
<p className="text-justify">Les dommages et les responsabilités sont définis à l'article 17.1 de la Convention de Montréal du 28 mai 1999.</p>
<h3><strong>16.4 </strong><strong>&nbsp;Dommages causés par les retards</strong></h3>
<p className="text-justify">Aucun référentiel en interne.</p>
<h3><strong>ARTICLE17- DELAIS DE RECLAMATIONS ET D'ACTIONS EN JUSTICE</strong></h3>
<h5> La réception des bagages enregistrés sans protestation par le passager dans les délais prévus constituera la présomption, sauf preuve contraire à la charge du passager, que les bagages ont été livrés en bon état et conformément au contrat de transport. Tout bagage manquant doit impérativement être signalé au transporteur dès l'arrivée du vol ou au plus tard dans un délai de 24 heures. Toute déclaration effectuée ultérieurement pourra ne pas être prise en compte. Toute déclaration tardive pourra ne pas être prise en considération.</h5>
{/* 
<h4>17.2 En cas de retard, détérioration, perte ou destruction des bagages, le passager concerné doit adresser une protestation par écrit auprès du transporteur dès que possible et au plus tard dans un délai respectivement de----jours (en cas de détérioration ou destruction) et de -------jours (en cas de retard) à compter de la date à laquelle les bagages ont été mis à sa disposition. A défaut de protestation dans les délais prévus, toutes actions contre le transporteur sont irrecevables, sauf le cas de fraude de la part de ce dernier. Si la protestation a été effectuée dans les délais prévus ---------ou -----jours) et qu'aucune conciliation n'a pu être trouvée entre le transporteur et le passager, celui-ci peut intenter une action en dommages et intérêts dans les deux ans suivant la date d'arrivée de l'avion, ou suivant la date à laquelle l'avion devait atterrir.&nbsp;</h4>

<p className="text-justify"> Selon les pratiques recommandées ////07 jours pour les dommages et 21 jours pour les retards.</p>
*/}

<h3><strong>17.3 </strong><strong>Limitation des actions </strong></h3>
<p className="text-justify">Toute action en responsabilité doit être intentée, sous peine de déchéance, dans un délai de deux ans à compter de l'arrivée à destination ou du jour o&ugrave; l'aéronef devait arriver ou de l'arrêt du transport. Le mode de calcul du délai sera déterminé par la loi du tribunal saisi.&nbsp;</p>
<p className="text-justify"><strong>ARTICLE 18- MODIFICATION ET RENONCIATION</strong></p>
<p className="text-justify">Aucun de nos agents, préposés, employés ou représentants n'a le pouvoir d'altérer, modifier ou renoncer à toute disposition des présentes Conditions de transport.</p>
{/* 
<p className="text-justify"><strong>TASSILI AIRLINES 20---</strong></p>
<p className="text-justify"><strong>Conditions Générales de Transport/Edition ---///mois-année</strong></p>
*/}













{/* 
<h2>NOTICE</h2>

<p className="text-justify">If the passenger’s journey involves an ultimate destination or stop in a country other than the country of departure the Warsaw Convention or the Montreal Convention may be applicable and these Conventions govern and may limit the liability of carriers for death or bodily injury and in respect of loss of or damage to baggage. See also notices headed “Advice to International Passengers on Limitation of Liability” and ‘Notice of Baggage Liability Limitations”</p>

<h3>CONDITIONS OF CONTRACT</h3>
<ol>
<li className="text-justify"> As used in this contract: “ticket” means this passenger ticket and baggage check or this itinerary/receipt if applicable, in the case of an electronic ticket, of which these conditions and the notices form part; “carriage” is equivalent to “transportation”; “carrier” means all air carriers that carry or undertake to carry the passenger or his baggage hereunder or perform any other service incidental to such air carriage; “electronic ticket” means the Itinerary/Receipt issued by or on behalf of Carrier, the Electronic Coupons and, if applicable, a boarding document; “Warsaw Convention” means the Convention for the Unification of Certain Rules relating to International Carriage by Air signed at Warsaw, 12th October 1929, or that Convention as amended at The Hague, 28th September 1955, whichever may be applicable.</li>

<li className="text-justify"> Carriage hereunder is subject to the rules and limitations relating to liability established by either the Warsaw Convention or the Montreal Convention unless such carriage is not “International Carriage” as defined by that Convention.</li>

<li className="text-justify"> To the extent not in conflict with the foregoing carriage and other services performed by each carrier are subject to: (i) provisions contained in the ticket; (ii) applicable tariffs; (iii) carrier’s conditions of carriage and related regulation which are made part hereof (and are available on application at the offices of carrier), except in transportation between a place in the United States or Canada and any place outside thereof to which tariffs in force in those countries apply.</li>

<li className="text-justify"> Carrier’s name may be abbreviated in the ticket, the full name and its abbreviation being set forth in carrier’s tariffs, conditions of carriage, regulations or timetables; carrier’s address shall be at the airport of departure shown opposite the first abbreviation of carrier’s name in the ticket; the agreed stopping places are those places set forth in this ticket, or itinerary/receipt or any supplement to it, or as shown in carrier’s timetable as scheduled stopping places on the passenger’s route; carriage to be performed hereunder by several successive carriers is regarded as a single operation.</li>

<li className="text-justify"> An air carrier issuing a ticket for carriage over the lines of another air carrier does so only as its agent.</li>

<li className="text-justify"> Any exclusion or limitation of liability of carrier shall apply to and be for the benefit of agents, servants and representatives of carrier and any person whose aircraft is used by carrier for carriage and its agents, servants and representatives.</li>

<li className="text-justify"> Checked baggage will be delivered to bearer of the baggage check. In case of damage to baggage moving in international transportation complaint must be made in writing to carrier forthwith after discovery of damage and, at the latest, within seven days from receipt; in case of delay, complaint must be made within 21 days from the date the baggage was delivered. See tariffs or conditions of carriage regarding non international transportation.</li>

<li className="text-justify"> This ticket is good for carriage for one year from date of issue except asotherwise provided in this ticket, in carrier’s tariffs, conditions of carriage, or related regulations. The fare for carriage hereunder is subject to change prior to commencement of carriage. Carrier may refuse transportation if the applicable fare has not been paid.</li>

<li className="text-justify"> Carrier undertakes to use its best efforts to carry the passenger and baggage with reasonable dispatch. Times shown in the Charter Agreement, this ticket or elsewhere are not guaranteed and form no part of this contract. Carrier may without notice substitute alternate carriers or aircraft, and may alter or omit stopping places shown on the ticket in case of necessity. Schedules are subject to change without notice. Carrier assumes no responsibility for making connections.</li>

<li className="text-justify"> Passengers shall comply with Government travel requirements, present exit, entry and other required documents and arrive at airport by time fixed by carrier or, if no time is fixed, early enough to complete departure procedures.</li>

<li className="text-justify"> No agent, servant or representative of carrier has authority to alter, modify or waive any provision of this contract.</li>
</ol>

<div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>


<h2>VERSION FRANCAISE</h2>

<p className="text-justify">Si le voyage du passager implique une destination finale ou un arrêt dans un pays autre que le pays de départ, la Convention de Varsovie ou la Convention de Montréal peut être applicable et ces Conventions régissent et peuvent limiter la responsabilité des transporteurs en cas de décès ou de lésions corporelles et en cas de perte. ou des dommages aux bagages. Voir également les avis intitulés «Conseils aux passagers internationaux sur la limitation de responsabilité» et «Avis de limitation de responsabilité des bagages»</p>

<h3>CONDITIONS DU CONTRAT</h3>
<ol>
<li className="text-justify"> Tel qu’utilisé dans le présent contrat: «billet» désigne ce billet de passager et l’enregistrement des bagages ou cet itinéraire / reçu le cas échéant, dans le cas d’un billet électronique, dont ces conditions et les avis font partie; «Transport» équivaut à «transport»; «Transporteur» désigne tous les transporteurs aériens qui transportent ou s’engagent à transporter le passager ou ses bagages en vertu des présentes ou effectuent tout autre service accessoire à ce transport aérien; «Billet électronique» désigne l’itinéraire / reçu émis par ou au nom du transporteur, les coupons électroniques et, le cas échéant, un document d’embarquement; «Convention de Varsovie» signifie la Convention pour l’unification de certaines règles relatives au transport aérien international signée à Varsovie, le 12 octobre 1929, ou cette Convention telle qu’amendée à La Haye, le 28 septembre 1955, selon le cas.</li>

<li className="text-justify"> Le transport en vertu des présentes est soumis aux règles et limitations de responsabilité établies par la Convention de Varsovie ou la Convention de Montréal, à moins que ce transport ne soit pas un «Transport international» tel que défini par cette Convention.</li>

<li className="text-justify"> Dans la mesure où ils ne sont pas en conflit avec le transport susmentionné et les autres services fournis par chaque transporteur sont soumis: (i) aux dispositions contenues dans le billet; (ii) les tarifs applicables; (iii) les conditions de transport du transporteur et la réglementation connexe qui en font partie (et sont disponibles sur demande auprès des bureaux du transporteur), sauf pour le transport entre un lieu aux États-Unis ou au Canada et tout lieu en dehors duquel des tarifs sont en vigueur dans ces pays s’appliquent.</li>

<li className="text-justify"> Le nom du transporteur peut être abrégé sur le billet, le nom complet et son abréviation étant indiqués dans les tarifs, conditions de transport, réglementations ou horaires du transporteur; l’adresse du transporteur doit être à l’aéroport de départ indiquée en face de la première abréviation du nom du transporteur sur le billet; les points d’arrêt convenus sont ceux indiqués sur ce billet, ou itinéraire / reçu ou tout supplément, ou comme indiqué dans l’horaire du transporteur comme points d’arrêt programmés sur le trajet du passager; le transport devant être effectué par plusieurs transporteurs successifs est considéré comme une seule opération.</li>

<li className="text-justify"> Un transporteur aérien qui délivre un billet pour le transport sur les lignes d’un autre transporteur aérien ne le fait qu’en qualité d’agent.</li>

<li className="text-justify"> Toute exclusion ou limitation de responsabilité du transporteur s’applique aux agents, préposés et représentants du transporteur et à toute personne dont l’aéronef est utilisé par le transporteur pour le transport et à ses agents, préposés et représentants et s’y applique.</li>

<li className="text-justify"> Les bagages enregistrés seront livrés au porteur de l’enregistrement des bagages. En cas de dommages aux bagages transportés dans le transport international, la réclamation doit être adressée par écrit au transporteur immédiatement après la découverte des dommages et, au plus tard, dans les sept jours suivant la réception; en cas de retard, la réclamation doit être effectuée dans un délai de 21 jours à compter de la date de livraison des bagages. Voir tarifs ou conditions de transport concernant le transport non international.</li>

<li className="text-justify"> Ce billet est valable pour le transport pendant un an à compter de la date d’émission, sauf indication contraire dans ce billet, dans les tarifs du transporteur, les conditions de transport ou les réglementations connexes. Le prix du transport ci-dessous est susceptible de changer avant le début du transport. Le transporteur peut refuser le transport si le tarif applicable n’a pas été payé.</li>

<li className="text-justify"> Le transporteur s’engage à faire ses meilleurs efforts pour transporter le passager et les bagages avec une diligence raisonnable. Les horaires indiqués dans le contrat d’affrètement, ce billet ou ailleurs ne sont pas garantis et ne font pas partie de ce contrat. Le transporteur peut, sans préavis, remplacer d’autres transporteurs ou avions, et peut modifier ou omettre les arrêts indiqués sur le billet en cas de nécessité. Les horaires sont sujets à changement sans préavis. Le transporteur n’assume aucune responsabilité pour l’établissement des connexions.</li>

<li className="text-justify"> Les passagers doivent se conformer aux exigences du gouvernement en matière de voyage, présenter les documents de sortie, d’entrée et autres documents requis et arriver à l’aéroport à l’heure fixée par le transporteur ou, si aucune heure n’est fixée, suffisamment tôt pour achever les procédures de départ.</li>

<li className="text-justify"> Aucun agent, préposé ou représentant du transporteur n’a le pouvoir d’altérer, de modifier ou de renoncer à toute disposition du présent contrat.</li>
</ol>

*/}









        
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour la mise à disposition d’appareils
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
    <Helmet>
      <title>Tassili Airlines || Charte de protection des données personnelles</title>
    </Helmet>
    {renderHeader()}

    <div className="nc-SingleContent container space-y-10">
      {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
