import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageBagage from "images/bagageCabine.png";
import imageSoute from "images/bagageSoute.png";
import imagematdang1 from "images/matdang/matdang1.png";
import imagematdang2 from "images/matdang/matdang2.png";
import imagematdang3 from "images/matdang/matdang3.png";
import imagematdang4 from "images/matdang/matdang4.png";
import imagematdang5 from "images/matdang/matdang5.png";
import imagematdang6 from "images/matdang/matdang6.png";
import imagematdang7 from "images/matdang/matdang7.png";
import imagematdang8 from "images/matdang/matdang8.png";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="space-y-5 text-center">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xlcontent-center relative left-1/2 transform -translate-x-1/2"
            title="Quiet ingenuity: 120,000 lunches and counting" 
          >
            LA MATIÈRE DANGEREUSE
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
      id="single-entry-content"
      className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
    >

<h3>1 Généralité</h3>
<p className="text-justify">Une marchandise dangereuse est définie comme une substance ou une matière (solide, liquide ou gazeuse) pouvant présenter un risque pour la santé, la sécurité, les biens ou l’environnement.</p>
<p className="text-justify">Certaines marchandises sont trop dangereuses pour être transportées par avion. Un certain nombre de limitations est applicable aux marchandises dangereuses autorisées à être transportées par voie aérienne. Ces limitations sont définies d’après la réglementation pour le transport des marchandises dangereuses (IATA).</p>
<p className="text-justify">Elle peut être placée dans une bouteille, un flacon, un équipement, un appareil, …</p>
<p className="text-justify">Il ne s’agit pas exclusivement de substances ou de matières explosives, toxiques, voire radioactives. Les substances et matières corrosives, inflammables, oxydantes, asphyxiantes, allergènes, pathogènes (infectieuses) sont aussi des marchandises dangereuses.</p>
<p className="text-justify">Les piles et batteries au lithium sont considérées comme des marchandises dangereuses. En cas d’endommagement, elles peuvent s’échauffer et prendre feu.</p>
<p className="text-justify">Compte tenu des risques pour la santé, la sécurité, les biens ou l’environnement, le transport aérien d’une marchandise dangereuse est soumis à une réglementation stricte qui a pour but de garantir la sécurité des vols.</p>


<h3>2  Classification des Matières Dangereuses</h3>
<p className="text-justify">Les matières dangereuses sont réparties en neuf classes, selon le type de risque qu’elles représentent pour la sécurité du transport aérien. La plupart des classes sont subdivisées selon les caractéristiques d’un produit donné. La présence des étiquettes suivantes traduit la présence d’une marchandise dangereuse.</p>

<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang1}
      />
<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang2}
      />
<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang3}
      />


<h3>3  Matières dangereuse cachées</h3>
<p className="text-justify">Certaines marchandises, déclarées sous une description très générale, peuvent présenter des caractéristiques dangereuses cachées derrière cette appellation inoffensive. De tels articles peuvent aussi se trouver dans les bagages des passagers.</p>
<p className="text-justify">Afin de prévenir le chargement de marchandises dangereuses non déclarées à bord d’un avion et l’introduction à bord, par les passagers, des marchandises dangereuses qu’ils ne sont pas autorisés à avoir dans leurs bagages, les passagers doivent prendre connaissance des marchandises cachées non autorisées non acceptés.</p>
<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang4}
      />

<h3>4 Marchandises dangereuses autorisées au transport par Tassili Airlines </h3>

<p className="text-justify">Les marchandises dangereuses dans le tableau ci-dessous (Table 2.3.A du manuel DGR/IATA) sont autorisées pour le transport dans les bagages de cabine ou dans les bagages de soute des passagers ou des membres d’équipage.</p>
<p className="text-justify">Certains articles exigent l’approbation de Tassili Airlines pour le transport. Cette approbation se fait par l’agent d’enregistrement formé en DGR et entrainé pour la vérification des limitations et des restrictions d’acceptation et de chargement pour chaque article.</p>

<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang5}
      />

<h3>NB : Ces articles contenant de la matière dangereuse doivent être retirés des bagages à   main lorsque les bagages de cabine sont transportés comme bagages de soute</h3>

<h3>5 Fiches d’information des matières dangereuses pour passagers</h3>

<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang6}
      />
<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang7}
      />

<h3>6 Liste des articles interdits en cabine et en soute</h3>


<NcImage
        className="w-full rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName=" my-10 sm:my-12 "
        src={imagematdang8}
      />

<h3>Articles interdits en cabine :</h3>


<p className="text-justify">Ces listes, portant sur des descriptions génériques, sont limitées aux articles liés à la sûreté et n’incluent pas les marchandises dangereuses.</p>


<h4>A.	Fusils, armes à feu et armes</h4>
<li className="text-justify">	Arbalètes.</li>
<li className="text-justify">	Armes-jouets de tous types.</li>
<li className="text-justify">	Armes à feu factices et d’imitation.</li>
<li className="text-justify">	Armes à feu dissimulées et camouflées.</li>
<li className="text-justify">	Briquets en forme d’armes à feu.</li>
<li className="text-justify">	Catapultes.</li>
<li className="text-justify">	Composantes de fusils et d’armes à feu, à l’exclusion des dispositifs télescopiques et de visée.</li>
<li className="text-justify">	Extincteurs.</li>
<li className="text-justify">	Fusils à air comprimé.</li>
<li className="text-justify">	Fusils à chenille.</li>
<li className="text-justify">	Fusils à plomb.</li>
<li className="text-justify">	Fusils de chasse.</li>
<li className="text-justify">	Harpons et lances.</li>
<li className="text-justify">	Matraques électroniques/aiguillons à bétail/ boîtiers paralysants, tasers.</li>
<li className="text-justify">	Pistolets d’abattage.</li>
<li className="text-justify">	Pistolets de départ.</li>
<li className="text-justify">	Pistolets lance-fusées.</li>

<h4>B.	Armes pointues et objets tranchants</h4>
<li className="text-justify">	Bâtons de ski.</li>
<li className="text-justify">	Ciseaux à lames de plus de 6 cm.</li>
<li className="text-justify">	Couperets à viande.</li>
<li className="text-justify">	Couteaux à cran d’arrêt à lames (toutes longueurs).</li>
<li className="text-justify">	Couteaux, aussi bien réels que de cérémonie, à lames de plus de 6 cm, faites en métal ou tout autre matériau suffisamment fort pour être utilisé comme arme potentielle.</li>
<li className="text-justify">	Crampons.</li>
<li className="text-justify">	Épées et crosses d’épée.</li>
<li className="text-justify">	Etoiles métalliques.</li>
<li className="text-justify">	Flèches et fléchettes.</li>
<li className="text-justify">	Haches et hachettes.</li>
<li className="text-justify">	Outils d’ouvrier pouvant être utilisés comme armes, notamment : perceuses, couteaux polyvalents, couteaux tout usage, scies de tous genres, tire-bouchons, barres à mine, marteaux, pinces étaux.</li>
<li className="text-justify">	Patins à glace.</li>
<li className="text-justify">	Rasoirs ouverts.</li>
<li className="text-justify">	Sabres.</li>
<li className="text-justify">	Scalpels.</li>
<li className="text-justify">	Aiguilles à tricoter.</li>
<li className="text-justify">	Ciseaux de moins de 6 cm.</li>
<li className="text-justify">	Couteaux de lame de moins de 6 cm.</li>
<li className="text-justify">	Coutellerie en métal.</li>
<li className="text-justify">	Lames de rasoir.</li>
<li className="text-justify">	Seringues hypodermiques à moins de preuves à l’appui d’une nécessité médicale.</li>
<li className="text-justify">	Tire-bouchons.</li>

<h4>C.	Instruments contondants</h4>
<li className="text-justify">	Assommoirs.</li>
<li className="text-justify">	Bâtons de golf.</li>
<li className="text-justify">	Bâtons de hockey.</li>
<li className="text-justify">	Bâtons de Lacrosse.</li>
<li className="text-justify">	Battes de baseball.</li>
<li className="text-justify">	Battes de cricket.</li>
<li className="text-justify">	Battes de softball.</li>
<li className="text-justify">	Instruments d’arts martiaux, notamment : coups de poing américains, massues, garcettes, fouets à riz et nunchucks, kubatons, kubasaunts.</li>
<li className="text-justify">	Matraques.</li>
<li className="text-justify">	Queues de billard, de snooker et de pari.</li>
<li className="text-justify">	Triques et bâtons.</li>

<h4>D.	Matières explosives et hautement inflammables</h4>
<li className="text-justify">	Capsules à jouets.</li>
<li className="text-justify">	Capsules détonantes.</li>
<li className="text-justify">	Cartouches de fumée.</li>
<li className="text-justify">	Chalumeaux.</li>
<li className="text-justify">	Détonateurs et mèches.</li>
<li className="text-justify">	Essence.</li>
<li className="text-justify">	Essence de térébenthine et diluants pour peinture.</li>
<li className="text-justify">	Essence pour briquets.</li>
<li className="text-justify">	Feux d’artifice.</li>
<li className="text-justify">	Fusées éclairantes de n’importe quelle forme.</li>
<li className="text-justify">	Gaz et contenants de gaz.</li>
<li className="text-justify">	Grenades.</li>
<li className="text-justify">	Matières ou engins explosifs.</li>
<li className="text-justify">	Matières ou engins explosifs factices ou d’imitation.</li>
<li className="text-justify">	Mines et autres matériels militaires explosifs.</li>
<li className="text-justify">	Munitions.</li>
<li className="text-justify">	Popper de fête.</li>

<h4>E.	Produits chimiques</h4>
<li className="text-justify">	Acides.</li>
<li className="text-justify">	Chlore et décolorants.</li>
<li className="text-justify">	Vaporisateurs incapacitants, notamment : maces, gaz poivrés, gaz lacrymogènes et acides.</li>

<h3>Articles interdits en soute :</h3>
<p className="text-justify">Les articles suivants ne doivent jamais être transportés dans les soutes à bagage. Il peut y avoir d’autres articles au sujet desquels le personnel de sûreté a des préoccupations et qui peuvent être :</p>
<li className="text-justify">	Confisqués s’ils sont trouvés.</li>
<li className="text-justify">	Capsules détonantes</li>
<li className="text-justify">	Détonateurs et mèches</li>
<li className="text-justify">	Engins explosifs</li>
<li className="text-justify">	Feux d’artifice</li>
<li className="text-justify">	Fusées éclairantes de n’importe quelle forme</li>
<li className="text-justify">	Grenades</li>
<li className="text-justify">	Mines et autres matériels militaires explosifs.</li>
<li className="text-justify">	Batteries au lithium de rechange.</li>












      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };




  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/*renderTags()*/}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentForm()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
