
 export  const items = [
  {
    "code": "AZR",
    "lat": "27.8378",
    "lon": "-0.1864",
    "name": "Touat Airport",
    "city": "Adrar",
    "state": "Adrar",
    "country": "Algeria",
    "woeid": "12510355",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "915",
    "icao": "DAUA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ALG",
    "lat": "36.6993",
    "lon": "3.21935",
    "name": "Houari Boumediene Airport",
    "city": "Algiers",
    "state": "Alger",
    "country": "Algeria",
    "woeid": "12510331",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.egsa.dz",
    "runway_length": "11483",
    "elev": "82",
    "icao": "DAAG",
    "direct_flights": "62",
    "carriers": "20"
  },
  {
    "code": "AAE",
    "lat": "36.8236",
    "lon": "7.8103",
    "name": "El Mellah Airport",
    "city": "Annaba",
    "state": "Annaba",
    "country": "Algeria",
    "woeid": "12510325",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "16",
    "icao": "DABB",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "CBH",
    "lat": "31.6583",
    "lon": "-2.2614",
    "name": "Bechar Airport",
    "city": "Bechar",
    "state": "Bechar",
    "country": "Algeria",
    "woeid": "12510314",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10064",
    "elev": "2661",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "BJA",
    "lat": "36.7133",
    "lon": "5.0697",
    "name": "Soummam Airport",
    "city": "Bejaia",
    "state": "Bejaia",
    "country": "Algeria",
    "woeid": "12510347",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "20",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "CZL",
    "lat": "36.2778",
    "lon": "6.6247",
    "name": "Ain El Bey Airport",
    "city": "Constantine",
    "state": "Constantine",
    "country": "Algeria",
    "woeid": "12510312",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "2303",
    "icao": "KCZL",
    "direct_flights": "13",
    "carriers": "2"
  },
  {
    "code": "ELU",
    "lat": "33.5122",
    "lon": "6.7842",
    "name": "Guemar Airport",
    "city": "El Oued",
    "state": "El Oued",
    "country": "Algeria",
    "woeid": "12510328",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "208",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "HME",
    "lat": "31.6739",
    "lon": "6.1428",
    "name": "Oued Irara Airport",
    "city": "Hassi Messaoud",
    "state": "Ouargla",
    "country": "Algeria",
    "woeid": "12510340",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "463",
    "icao": "",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "IAM",
    "lat": "28.0511",
    "lon": "9.6381",
    "name": "In Amenas Airport",
    "city": "Illizi",
    "state": "Illizi",
    "country": "Algeria",
    "woeid": "12510332",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "aeroport-iam@egsa-alger.com",
    "url": "http:\/\/www.egsa-alger.com\/index.php?id=89",
    "runway_length": "9843",
    "elev": "1847",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "INZ",
    "lat": "27.2453",
    "lon": "2.5164",
    "name": "In Salah Airport",
    "city": "In Salah",
    "state": "Tamanghasset",
    "country": "Algeria",
    "woeid": "12510334",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "883",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "NTE",
    "lat": "47.1573",
    "lon": "-1.60205",
    "name": "Chateau Bougon Airport",
    "city": "Bouguenais",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "12512881",
    "tz": "Europe\/Paris",
    "phone": "+33 (0)2 40 84 81 52",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nantes.aeroport.fr",
    "runway_length": "9514",
    "elev": "89",
    "icao": "LFRS",
    "direct_flights": "38",
    "carriers": "23"
  },
  {
    "code": "ORN",
    "lat": "35.6244",
    "lon": "-0.6186",
    "name": "Ahmed Ben Bella Airport",
    "city": "Oran Rp",
    "state": "Oran",
    "country": "Algeria",
    "woeid": "12510326",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "295",
    "icao": "",
    "direct_flights": "23",
    "carriers": "3"
  },
  {
    "code": "CDG",
    "lat": "49.0175",
    "lon": "2.5564",
    "name": "Charles de Gaulle International T1",
    "city": "Paris",
    "state": "Paris",
    "country": "France",
    "woeid": "22140453",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11811",
    "elev": "387",
    "icao": "LFPG",
    "direct_flights": "310",
    "carriers": "131"
  },
  {
    "code": "SXB",
    "lat": "48.5427",
    "lon": "7.63466",
    "name": "Strasbourg Airport",
    "city": "Strasbourg",
    "state": "Alsace",
    "country": "France",
    "woeid": "12512892",
    "tz": "Europe\/Paris",
    "phone": "+ 33 3 88 64 67 67",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.strasbourg.aeroport.fr",
    "runway_length": "7874",
    "elev": "502",
    "icao": "LFST",
    "direct_flights": "30",
    "carriers": "20"
  },
  {
    "code": "TMR",
    "lat": "22.8147",
    "lon": "5.4592",
    "name": "Tamanrasset Airport",
    "city": "Tamanrasset",
    "state": "Tamanghasset",
    "country": "Algeria",
    "woeid": "12510350",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "TGR",
    "lat": "33.0675",
    "lon": "6.0883",
    "name": "Touggourt Airport",
    "city": "Ouargla",
    "state": "Ouargla",
    "country": "Algeria",
    "woeid": "12510356",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TIN",
    "lat": "39.5354",
    "lon": "-93.4399",
    "name": "Tindouf Airport",
    "city": "Tindouf",
    "state": "Tindouf",
    "country": "Algeria",
    "woeid": "1257822",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  }
]